import gql from "graphql-tag";

export const ADMIN_FILE_UPLOAD = gql`
  mutation adminFileUpload(
    $postId: String!
    $fileName: String!
    $fileType: String!
  ) {
    adminFileUpload(postId: $postId, fileName: $fileName, fileType: $fileType) {
      payload {
        file {
          name
          type
        }
      }
      status
      token
      message
    }
  }
`;
