import React from "react";
import { useMutation, useQuery } from "react-apollo";
import { toast } from "react-toastify";
import { Button, Container, Form, Icon, Modal } from "semantic-ui-react";
import {
  CREATE_CATEGORY,
  DELETE_CATEGORY,
  TOGGLE_CATEGORY,
} from "../../apollo/mutation";
import { GET_CATEGORIES } from "../../apollo/query";
import TitleHeader from "../../components/TitleHeader";

export const CategoryPage = (props) => {
  const initialState = {
    open: false,
    deleteModal: false,
    disableModal: false,
    category: { id: null, enable: null },
    name: "",
  };
  const [state, setState] = React.useState(initialState);
  const { open, deleteModal, disableModal, category, name } = state;

  const { data } = useQuery(GET_CATEGORIES, {
    variables: { page: 1, limit: 3000 },
  });

  const [addCategory] = useMutation(CREATE_CATEGORY, {
    variables: { name },
    onCompleted: (data) => {
      console.log({ data });
      setState(initialState);
      toast(
        data.createCategory
          ? "Category added."
          : "Category addition unsuccessful."
      );
    },
    onError: (error) => {
      console.log(error.message);
      toast(error.message);
    },
    update: () => {},
  });

  const [deleteCategory] = useMutation(DELETE_CATEGORY, {
    onCompleted: (data) => {
      console.log({ data });
      toast(
        data.deleteCategory
          ? "Category deleted."
          : "Category deletion unsuccessful."
      );
      setState(initialState);
    },
    onError: (error) => {
      console.log(error.message);
      toast(error.message);
    },
    update: () => {},
  });

  const [disableCategory] = useMutation(TOGGLE_CATEGORY, {
    onCompleted: (data) => {
      console.log({ data });
      toast(
        data.toggleCategory
          ? `Category ${category.enable ? "disabled" : "enabled"}.`
          : "Category disabled unsuccessful."
      );
      setState(initialState);
    },
    onError: (error) => {
      console.log(error.message);
      toast(error.message);
    },
    update: () => {},
  });

  const handleOpen = () => {
    setState((prevState) => ({ ...state, open: !prevState.open }));
  };

  const handleDeleteModal = (id = "") => {
    setState((prevState) => ({
      ...state,
      deleteModal: !prevState.deleteModal,
      category: { id },
    }));
  };

  const handleDisableModal = (id = "", enable) => {
    setState((prevState) => ({
      ...state,
      disableModal: !prevState.disableModal,
      category: { id, enable },
    }));
  };

  const payload = data?.categories?.payload || [];

  return (
    <Container>
      <TitleHeader
        title="Category"
        buttonTitle="Add Category"
        buttonAction={handleOpen}
      />
      {payload.map((category, index) => {
        console.log(category);
        return (
          <div
            key={category._id}
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 20,
              // border: "1px solid red",
            }}
          >
            <div style={{ color: "#000" }}>
              {index + 1} : {category.name}
            </div>
            <div
              style={{
                color: category.enable ? "green" : "red",
                cursor: "pointer",
              }}
            >
              <span
                onClick={() =>
                  handleDisableModal(category._id, category.enable)
                }
              >
                {category.enable ? "Enabled" : "Disabled"}
              </span>
              <Icon
                name="trash alternate"
                color="red"
                style={{ marginLeft: 20 }}
                onClick={() => handleDeleteModal(category._id)}
              />
            </div>
          </div>
        );
      })}
      {/* Add Category Modal */}
      <Modal size="mini" open={open} onClose={handleOpen}>
        <Modal.Header>Add Category</Modal.Header>
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Name</label>
              <input
                placeholder="Enter category name"
                type="text"
                value={name}
                onChange={(e) => {
                  setState({ ...state, name: e.target.value });
                }}
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={handleOpen}>
            Close
          </Button>
          <Button positive onClick={addCategory}>
            Add
          </Button>
        </Modal.Actions>
      </Modal>
      {/* Disable Modal */}
      <Modal size={"mini"} open={disableModal} onClose={handleDisableModal}>
        <Modal.Header>Disable Category</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to disable this category ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={handleDisableModal}>
            No
          </Button>
          <Button
            positive
            onClick={() =>
              disableCategory({
                variables: {
                  categoryId: category.id,
                  enable: !category.enable,
                },
              })
            }
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
      {/* Delete Modal */}
      <Modal size={"mini"} open={deleteModal} onClose={handleDeleteModal}>
        <Modal.Header>Delete Category</Modal.Header>
        <Modal.Content>
          <p>Are you sure you want to delete this category ?</p>
        </Modal.Content>
        <Modal.Actions>
          <Button negative onClick={handleDeleteModal}>
            No
          </Button>
          <Button
            positive
            onClick={() =>
              deleteCategory({
                variables: { categoryId: category.id, transferId: "" },
              })
            }
          >
            Yes
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};
