import gql from "graphql-tag";

export const ATTACH_CATEGORY_TO_POST = gql`
  mutation attachCategoriesToPost($postId: String!, $categoryIds: [String!]!) {
    attachCategoriesToPost(postId: $postId, categoryIds: $categoryIds) {
      payload {
        _id
        title
        categoryIds
        category {
          name
        }
      }
    }
  }
`;
