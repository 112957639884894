import gql from "graphql-tag";

export const GET_POST = gql`
  query getPost($postId: String!) {
    postForUser(postId: $postId) {
      payload {
        _id
        title
        file {
          name
          type
        }
        description
        categoryIds
        category {
          name
        }
        tags
        language
        likes
        authorId
        placeId
        place {
          name
        }
        author {
          _id
          name
          mobile
        }
        location {
          type
          coordinates
        }
        isLiked
        verified
        enable
        createdAt
        updatedAt
        marks {
          _id
          markingUser {
            name
          }
          markedUser {
            name
          }
          remarks
          enable
        }
      }
      token
      message
      status
    }
  }
`;
