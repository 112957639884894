// HOST Variables

export const Secure =
  process.env.NODE_ENV === "production" ? "https://" : "https://";
export const HOST =
  process.env.NODE_ENV === "production"
    ? "via.cgseconno.in"
    : "via.cgseconno.in";
export const BASE_URL = `${Secure}${HOST}`;

// Secret codes
export const PUBLISH_SECRET = "12345";
export const DELETE_SECRET = "12345";

export const CLOUDINARY_URL = `https://viaupload.cgseconno.in/upload`;

export const VIEW_POST_IMAGE = (fileName) => {
  const file = fileName.split(".");
  return `https://viastream.cgseconno.in/images/${file[0]}/index.jpg`;
};

export const TRANS640x360 = "640x360";
export const TRANS480x270 = "480x270";

export const VIEW_POST_VIDEO = (fileName) => {
  const file = fileName.split(".");
  return `https://viastream.cgseconno.in/videos/${file[0]}/transcode/index.m3u8`;
};
export const VIEW_VIDEO_PREVIEW = (fileName) => {
  const file = fileName.split(".");
  return `https://viaupload.cgseconno.in/videos/${file[0]}/preview/index.jpg`;
};
