import gql from "graphql-tag";

export const GET_STATES = gql`
  {
    states {
      payload {
        id
        name
      }
    }
  }
`;
