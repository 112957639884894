import gql from "graphql-tag";

export const GET_ADMIN_USERS = gql`
  query adminUsers(
    $page: Int
    $limit: Int
    $filter: AdminUserFilter
    $search: String
  ) {
    adminUsers(page: $page, limit: $limit, filter: $filter, search: $search) {
      payload {
        _id
        name
        email
        password
        role
        logo
        enable
        createdAt
        updatedAt
      }
      count
      page
      limit
    }
  }
`;
