import gql from "graphql-tag";

export const ADD_PLACES = gql`
  mutation createPlace(
    $name: String!
    $stateId: String!
    $long: Float!
    $lat: Float!
  ) {
    createPlace(name: $name, stateId: $stateId, long: $long, lat: $lat) {
      payload {
        name
      }
    }
  }
`;
