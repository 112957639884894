import React from "react";
import { Card } from "semantic-ui-react";

const DashBoardCard = ({ title, count }) => (
  <Card>
    <Card.Content>
      <Card.Header>{title}</Card.Header>
      <Card.Description>{count}</Card.Description>
    </Card.Content>
    <Card.Content extra></Card.Content>
  </Card>
);

export default DashBoardCard;
