import React from "react";
import { Modal, Icon } from "semantic-ui-react";
import MediaAssetViewer from "../MediaAssetViewer";
import GlobalController from "../../utils/GlobalController";
import { VIEW_POST_VIDEO, VIEW_POST_IMAGE } from "../../config/constants";
import { motion } from "framer-motion";

class MediaIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    const { file, title } = this.props;
    const { isImage } = GlobalController.sourceResolver(file);
    return (
      <React.Fragment>
        <Icon
          name={isImage ? "images" : "video play"}
          size="huge"
          onClick={this.show}
          style={{
            marginTop: 10,
            marginLeft: 20,
            marginRight: 20,
            cursor: "pointer",
            color: "#1B91DA",
          }}
        />

        <Modal dimmer open={open} onClose={this.close}>
          <Modal.Content>
            <Modal.Description>
              <motion.div
                animate={{
                  scale: [1, 1.2, 1.2, 1, 1],
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div>
                    <h3>{title}</h3>
                  </div>
                  <div>
                    <a
                      href={`${isImage ? VIEW_POST_IMAGE : VIEW_POST_VIDEO}${
                        file.name
                      }`}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      <Icon
                        circular
                        inverted
                        color="blue"
                        name="download"
                        onClick={this.close}
                        style={{ cursor: "pointer" }}
                      />
                    </a>
                    <Icon
                      circular
                      inverted
                      color="red"
                      name="cancel"
                      onClick={this.close}
                      style={{ cursor: "pointer" }}
                    />
                  </div>
                </div>
                <MediaAssetViewer file={file} />
              </motion.div>
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </React.Fragment>
    );
  }
}

export default MediaIcon;
