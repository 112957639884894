export const MODERATOR = [
  {
    key: 1,
    name: "DashBoard",
    to: "/",
  },
  {
    key: 2,
    name: "Unverified Users Posts",
    to: "/posts",
  },
  {
    key: 3,
    name: "Marked Posts",
    to: "/marked_posts",
  },
];

export const EDITOR = [
  {
    key: 1,
    name: "DashBoard",
    to: "/",
  },
  {
    key: 2,
    name: "Unverified Users Posts",
    to: "/posts",
  },
  {
    key: 3,
    name: "member_posts",
    to: "/member_posts",
  },
  {
    key: 4,
    name: "Marked Posts",
    to: "/marked_posts",
  },
];

export const CONTENTEDITOR = [
  {
    key: 1,
    name: "DashBoard",
    to: "/",
  },
  {
    key: 2,
    name: "Marked Posts",
    to: "/marked_posts",
  },
];

export const SENIOREDITOR = [
  {
    key: 1,
    name: "DashBoard",
    to: "/",
  },
  {
    key: 2,
    name: "Live Posts Broadcasted",
    to: "/broadcast",
  },
  {
    key: 3,
    name: "Unverified Users Posts",
    to: "/posts",
  },
  {
    key: 4,
    name: "member_posts",
    to: "/member_posts",
  },
  {
    key: 5,
    name: "Marked Posts",
    to: "/marked_posts",
  },
  {
    key: 5,
    name: "Trash Posts",
    to: "/trash",
  },
];

export const SUPERADMIN = [
  {
    key: 1,
    name: "DashBoard",
    to: "/",
  },
  {
    key: 2,
    name: "Live Posts Broadcasted",
    to: "/broadcast",
  },
  {
    key: 3,
    name: "Unverified Users Posts",
    to: "/posts",
  },
  {
    key: 4,
    name: "member_posts",
    to: "/member_posts",
  },
  {
    key: 5,
    name: "Marked Posts",
    to: "/marked_posts",
  },
  {
    key: 6,
    name: "Trash Posts",
    to: "/trash",
  },
];
