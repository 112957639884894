import gql from "graphql-tag";

export const USER_POSTS_COUNT = gql`
  query userPosts(
    $page: Int
    $limit: Int
    $authorId: String!
    $search: String
  ) {
    userPosts(
      page: $page
      limit: $limit
      authorId: $authorId
      search: $search
    ) {
      count
    }
  }
`;
