import React from "react";
import "./App.css";
import { BrowserRouter, Route, Redirect } from "react-router-dom";
// import { auth } from "./config/fire";
import DashBoardPage from "./pages/DashBoardPage/DashBoardPage";
import LoginPage from "./pages/LoginPage";
import DashboardLayout from "./layouts/DashboardLayout";
import VerifiedPage from "./pages/VerifiedPage";
import OrganizationDetails from "./pages/OrganizatioDetails";
import TrashPage from "./pages/TrashPage/TrashPage";
import VerifiedUserRequest from "./pages/VerifiedUserRequestPage/VerifiedUserRequest";
import UploadedPage from "./pages/UploadedPage";
import ViewReadStoryPage from "./pages/ViewReadStoryPage/ViewReadStoryPage";
import BroadcastPage from "./pages/BroadcastPage/BroadcastPage";
import RegisterdUsers from "./pages/RegisterdUsers/RegisterdUsers";
import MarkedPage from "./pages/MarkedPage/MarkedPage";
import VerifiedUsersPostsPage from "./pages/VerifiedUsersPostsPage";
import { connect } from "react-redux";
import { reset } from "./redux/actions/rootAction";
import PlacePage from "./pages/PlacePage/PlacePage";
import CategoryPage from "./pages/CategoryPage";
import MapPage from "./pages/MapPage.js/MapPage";
import CreateCity from "./pages/CityPage/CreateCity";

const Broadcast = () => {
  return (
    <DashboardLayout>
      <BroadcastPage />
    </DashboardLayout>
  );
};

const Marked = () => {
  return (
    <DashboardLayout>
      <MarkedPage />
    </DashboardLayout>
  );
};

const registerdUsers = () => {
  return (
    <DashboardLayout>
      <RegisterdUsers />
    </DashboardLayout>
  );
};

const verifieduserrequest = () => {
  return (
    <DashboardLayout>
      <VerifiedUserRequest />
    </DashboardLayout>
  );
};
const Uploaded = () => (
  <DashboardLayout>
    <UploadedPage />
  </DashboardLayout>
);

const Trash = () => (
  <DashboardLayout>
    <TrashPage />
  </DashboardLayout>
);

const verifiedUsersPosts = () => (
  <DashboardLayout>
    <VerifiedUsersPostsPage />
  </DashboardLayout>
);

const verified = () => (
  <DashboardLayout>
    <VerifiedPage />
  </DashboardLayout>
);

const organizationdetails = () => (
  <DashboardLayout>
    <OrganizationDetails />
  </DashboardLayout>
);

const readviewstory = () => (
  <DashboardLayout>
    <ViewReadStoryPage />
  </DashboardLayout>
);
const Place = () => (
  <DashboardLayout>
    <PlacePage />
  </DashboardLayout>
);
const Category = () => (
  <DashboardLayout>
    <CategoryPage />
  </DashboardLayout>
);
const Map = () => (
  <DashboardLayout>
    <MapPage />
  </DashboardLayout>
);

const City = () => (
  <DashboardLayout>
    <CreateCity />
  </DashboardLayout>
);
const DashBoard = () => (
  <DashboardLayout>
    <DashBoardPage />
  </DashboardLayout>
);

const Logout = connect(null, (dispatch) => ({
  reset: () => dispatch(reset()),
}))((props) => {
  props.reset();
  localStorage.clear();
  return <Redirect to="/login" />;
});

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: {},
    };
  }

  // componentDidMount() {
  //   this.authListener();
  // }

  // authListener() {
  //   auth.onAuthStateChanged((user) => {
  //     if (user) {
  //       this.setState({ user });
  //     } else {
  //       this.setState({ user: null });
  //     }
  //   });
  // }

  render() {
    return (
      <div>
        <BrowserRouter>
          {/* {this.state.user ? <Stories /> : <LoginPage />} */}
          <Route exact path="/login" component={LoginPage} />
          <Route exact path="/logout" component={Logout} />

          <Route exact path="/" component={DashBoard} />
          <Route exact path="/broadcast" component={Broadcast} />
          <Route exact path="/posts" component={Uploaded} />
          <Route
            exact
            path="/verified_users_posts"
            component={verifiedUsersPosts}
          />
          <Route exact path="/member_posts" component={verified} />
          <Route exact path="/marked_posts" component={Marked} />
          <Route exact path="/members" component={organizationdetails} />
          <Route exact path="/trash" component={Trash} />
          <Route exact path="/users" component={registerdUsers} />
          <Route exact path="/requests" component={verifieduserrequest} />
          {/* Individual Post  */}
          <Route exact path="/post/:postId" component={readviewstory} />
          <Route exact path="/place" component={Place} />
          <Route exact path="/category" component={Category} />
          <Route exact path="/map" component={Map} />
          <Route exact path="/city" component={City} />
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
