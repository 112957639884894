import gql from "graphql-tag";

export const PLACES_FOR_ADMIN_QUERY = gql`
  query placesForAdmin(
    $page: Int
    $limit: Int
    $search: String
    $filter: PlaceFilter
  ) {
    placesForAdmin(
      page: $page
      limit: $limit
      search: $search
      filter: $filter
    ) {
      payload {
        _id
        name
        state_id
        location {
          type
          coordinates
        }
        postCount
        geoSphere
        state {
          name
          country_id
        }
      }
      count
      page
      limit
    }
  }
`;
