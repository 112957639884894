import React, { useState } from "react";
import { Radio, Modal, Button, Form } from "semantic-ui-react";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";
import style from "./user.module.css";
import { useMutation } from "react-apollo";
import { VERIFY_POST } from "../../apollo/mutation/verifyPost";

const PostPublishButton = (props) => {
  const [open, setOpen] = useState(false);
  const {
    postId,
    status,
    setStatus,
    publishButton,
    isPublishing,
    postVerified,
  } = props;

  const [verifyPost] = useMutation(VERIFY_POST, {
    onCompleted: (data) => {
      console.log(data);
      if (data.verifyPost) {
        toast("Story Published");
        setTimeout(function () {
          props.history.goBack();
        }, 2000);
      } else {
        toast("Story UnPublished");
        setTimeout(function () {
          props.history.goBack();
        }, 2000);
      }
      setOpen(false);
      setStatus(data.verifyPost);
    },
  });

  return (
    <>
      {publishButton ? (
        <Button
          basic
          color={postVerified ? "red" : "green"}
          className="radius"
          // content={postVerified ? "Unpublish" : "Publish Story"}
          content="Publish Story"
          onClick={() => setOpen(true)}
        />
      ) : (
        <Radio
          toggle
          onClick={() => setOpen(true)}
          checked={status}
          style={{ backgroundColor: "#efefef" }}
        />
      )}

      <Modal dimmer open={open} onClose={() => setOpen(false)}>
        <Modal.Header className={style.textcenter}>
          {status
            ? "Unpublish Video from broadcast ?"
            : "You Want To Publish Story "}
          {status ? (
            <p className={style.notificationdesc}>
              Are you sure you want to unpublish story from live broadcast ??
            </p>
          ) : (
            <p className={style.notificationdesc}>
              Are you sure you want to publish story in live broadcast ??
            </p>
          )}
        </Modal.Header>
        <Modal.Content>
          <Modal.Description>
            <Form style={{ padding: "0 100px" }}>
              <br />
              <input placeholder="Enter your secret delete code" required />
            </Form>
            {/* <p>Is it okay to use this photo?</p> */}
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          <div className={style.btnflex}>
            <Button
              color="green"
              loading={isPublishing}
              onClick={() =>
                verifyPost({ variables: { postId, verify: !status } })
              }
            >
              {status ? "Unpublish" : "Publish"}
            </Button>

            <Button color="red" onClick={() => setOpen(false)}>
              Cancel
            </Button>
          </div>
        </Modal.Actions>
      </Modal>
    </>
  );
};

export default withRouter(PostPublishButton);
