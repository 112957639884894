import axios from "axios";
import { CLOUDINARY_URL } from "../config/constants";

const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    try {
      const formData = new FormData();
      formData.append("file", file);
      const result = axios.post(CLOUDINARY_URL, formData, {
        headers: { "X-Requested-With": "XMLHttpRequest" },
      });
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

export { uploadFile };
