import gql from "graphql-tag";

export const PLACES_QUERY = gql`
  query places($page: Int, $limit: Int, $search: String, $filter: PlaceFilter) {
    places(page: $page, limit: $limit, search: $search, filter: $filter) {
      payload {
        _id
        name
        state_id
        location {
          type
          coordinates
        }
        postCount
        geoSphere
        state {
          name
          country_id
          #   country {
          #     name
          #     sortname
          #   }
        }
      }
      count
      page
      limit
    }
  }
`;
