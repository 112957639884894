import gql from "graphql-tag";

export const GET_CATEGORIES = gql`
  query categories($page: Int, $limit: Int) {
    categories(page: $page, limit: $limit) {
      payload {
        _id
        name
        enable
        createdAt
        updatedAt
      }
      count
      page
      limit
    }
  }
`;
