import React from "react";
import GlobalController from "../../utils/GlobalController";
// import Player from "video-react/lib/components/Player";
import // Player,
// ControlBar,
// ForwardControl,
// BigPlayButton,
// ReplayControl,
"video-react";
// import HLSSource from "./HLSSource";
import ReactHlsPlayer from "react-hls-player";

import { Segment, Icon } from "semantic-ui-react";

const MediaAssetViewer = ({ file, avatar = false }) => {
  const { isImage, sourceURI } = GlobalController.sourceResolver(file);
  console.log(sourceURI);
  /**
   * ******************************************************************
   * Checking "avatar" value, if its true than avatar viewed with as "isImage" value.
   * ******************************************************************
   */
  if (avatar) {
    if (isImage) {
      return (
        <Icon name="image outline" size="huge" style={{ cursor: "pointer" }} />
      );
    } else {
      return (
        <Icon name="video play" size="huge" style={{ cursor: "pointer" }} />
      );
    }
  } else {
    console.log(sourceURI);
    /**
     * **************************************************************
     * Checking if "avatar" not passed, than checking "isImage", if "true" than return <img /> otherwise video <Player />.
     * **************************************************************
     */
    if (isImage) {
      return (
        <img
          style={{ width: "100%", height: 300, marginTop: 10 }}
          alt={file.name}
          src={sourceURI}
        />
      );
    } else {
      return (
        <Segment raised style={{ border: "1px solid red" }}>
          {/* <Player src={sourceURI} fluid={true}> */}
          {/* <ControlBar autoHide={true}>
              <ReplayControl seconds={10} order={2.2} />
              <ForwardControl seconds={10} order={3.2} />
            </ControlBar>
            <BigPlayButton position="center" /> */}
          {/* <HLSSource isVideoChild src={sourceURI} /> */}
          {/* </Player> */}
          <ReactHlsPlayer
            url={sourceURI}
            autoplay={true}
            controls={true}
            height={375}
            hlsConfig={{
              maxBufferLength: 1,
              liveSyncDurationCount: 4,
              autoStartLoad: true,
              startPosition: -1,
            }}
          />
        </Segment>
      );
    }
  }
};

export default MediaAssetViewer;
