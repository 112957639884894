import gql from "graphql-tag";

export const CREATE_CATEGORY = gql`
  mutation createCategory($name: String!) {
    createCategory(name: $name) {
      payload {
        _id
        name
        enable
        createdAt
        updatedAt
      }
    }
  }
`;
