import gql from 'graphql-tag';

export const LOGIN = gql`
  mutation login($mobile: String!) {
    login(mobile: $mobile) {
      payload {
        mobile
      }
      token
      message
      status
    }
  }
`;
