// Add member button

import React from "react";
import style from "./button.module.css";
import {
  Icon,
  Container,
  Modal,
  Form,
  Button,
  Input,
  Header,
} from "semantic-ui-react";
import { useMutation } from "react-apollo";
import { ADD_ADMIN } from "../../apollo/mutation";

const roles = [
  { key: "1", value: "MODERATOR", text: "MODERATOR" },
  { key: "2", value: "EDITOR", text: "EDITOR" },
  { key: "3", value: "SENIOREDITOR", text: "SENIOREDITOR" },
  { key: "4", value: "CONTENTEDITOR", text: "CONTENTEDITOR" },
  { key: "5", value: "SUPERADMIN", text: "SUPERADMIN" },
];

const AddOrganizationButton = () => {
  const [user, setUser] = React.useState({
    name: "",
    email: "",
    password: "",
    role: "",
    open: false,
  });

  const [addAdmin, { loading, error, data }] = useMutation(ADD_ADMIN);

  const formSubmit = (e) => {
    e.preventDefault();
    const { name, email, password, role } = user;
    if (name === "") {
      return;
    }
    if (email === "") {
      return;
    }
    if (password === "") {
      return;
    }
    if (role === "") {
      return;
    }
    addAdmin({ variables: { adminUser: { name, email, password, role } } });
    close();
  };

  const handleChange = (e) => {
    setUser({
      ...user,
      [e.target.name]: e.target.value,
    });
  };

  const show = () => setUser({ ...user, open: true });
  const close = () => setUser({ ...user, open: false });

  const { open, name, email, password, role } = user;

  const AddButton = () => (
    <React.Fragment>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "2px solid #FFF",
        }}
      >
        <div>
          <Header size="huge" style={{ color: "#fff" }}>
            Add Admin User{" "}
          </Header>
        </div>
        <div>
          <Icon
            name="add square"
            size="big"
            inverted
            onClick={() => show()}
            className={style.pointer}
          />
        </div>
      </div>
    </React.Fragment>
  );

  return (
    <React.Fragment>
      <Container>
        <AddButton />
        <Modal dimmer open={open} onClose={() => close}>
          <Modal.Header className={style.textcenter}>Add Member</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              {error ? <p>{error.message}</p> : null}
              {data ? (
                <p>Successfully Created.</p>
              ) : (
                <Form
                  className={style.formpadding}
                  onSubmit={(e) => formSubmit(e)}
                >
                  <Form.Field inline>
                    <label>Name</label>
                    <Input
                      type="text"
                      placeholder="Enter name"
                      fluid
                      name="name"
                      value={name}
                      onChange={(e) => handleChange(e)}
                      disabled={loading}
                    />
                  </Form.Field>

                  <Form.Field inline>
                    <label>Email</label>
                    <Input
                      type="email"
                      placeholder="Enter email"
                      fluid
                      name="email"
                      value={email}
                      onChange={(e) => handleChange(e)}
                      disabled={loading}
                    />
                  </Form.Field>

                  <Form.Field inline>
                    <label>Password</label>
                    <Input
                      type="password"
                      placeholder="Enter password"
                      fluid
                      name="password"
                      value={password}
                      onChange={(e) => handleChange(e)}
                      disabled={loading}
                    />
                  </Form.Field>

                  <Form.Field inline>
                    <label>Roles</label>
                    <select
                      name="role"
                      placeholder="select Category"
                      style={{ width: "100%" }}
                      value={role}
                      onChange={(e) => handleChange(e)}
                      disabled={loading}
                    >
                      <option value="">Select Category</option>
                      {roles.map((role) => (
                        <option key={role.key} value={role.value}>
                          {role.text}
                        </option>
                      ))}
                    </select>
                  </Form.Field>

                  <Modal.Actions>
                    <Button
                      color="green"
                      type="submit"
                      floated="right"
                      disabled={loading}
                      loading={loading}
                    >
                      Add Member
                    </Button>
                    <Button
                      type="button"
                      color="red"
                      onClick={() => close()}
                      floated="right"
                      disabled={loading}
                    >
                      Cancel
                    </Button>
                  </Modal.Actions>
                  <br />
                  <br />
                </Form>
              )}
            </Modal.Description>
          </Modal.Content>
        </Modal>
      </Container>
    </React.Fragment>
  );
};

export default AddOrganizationButton;
