import gql from "graphql-tag";

export const GET_POSTS = gql`
  query getPosts(
    $page: Int
    $limit: Int
    $filter: PostFilter
    $search: String
  ) {
    postsForAdmin(
      page: $page
      limit: $limit
      filter: $filter
      search: $search
    ) {
      payload {
        _id
        title
        file {
          name
          type
        }
        description
        tags
        language
        likes
        authorId
        placeId
        place {
          name
          state {
            name
          }
        }
        author {
          _id
          name
          mobile
        }
        location {
          type
          coordinates
        }
        isLiked
        verified
        enable
        createdAt
        updatedAt
      }
      count
      limit
      page
    }
  }
`;
