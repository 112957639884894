import React, { useState } from "react";
import { Table, Radio, Icon } from "semantic-ui-react";
import { TOGGLE_ADMIN_USER } from "../../apollo/mutation";
import { useMutation } from "react-apollo";
import { toast } from "react-toastify";

const OrganizationDetailTable = ({ members, handleDelete }) => {
  return (
    <React.Fragment>
      <Table singleLine>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Logo</Table.HeaderCell>
            <Table.HeaderCell>FullName</Table.HeaderCell>
            <Table.HeaderCell>Expertise</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {members.map((member, i) => (
            <MemberDetails
              key={i}
              member={member}
              handleDelete={(id) =>
                handleDelete({ variables: { adminUserId: id } })
              }
            />
          ))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );
};

export default OrganizationDetailTable;

const MemberDetails = ({ member, handleDelete }) => {
  const { _id, name, email, role, logo, enable } = member;

  const [userEnable, setUserEnable] = useState(enable);

  const [handleStatus, { loading }] = useMutation(TOGGLE_ADMIN_USER, {
    onCompleted: (data) => {
      console.log(data);
      if (userEnable) {
        toast("Admin enabled");
      } else {
        toast("Admin disabled");
      }
    },
  });

  return (
    <Table.Row>
      <Table.Cell>{logo ? logo : "Logo"}</Table.Cell>
      <Table.Cell>{name ? name : "Not Available"}</Table.Cell>
      <Table.Cell>{role ? role : "Not Available"}</Table.Cell>
      <Table.Cell>{email ? email : "Not Available"}</Table.Cell>
      <Table.Cell>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
          }}
        >
          <Radio
            disabled={loading}
            toggle
            checked={userEnable}
            onChange={(e, data) => {
              handleStatus({
                variables: { adminUserId: _id, enable: data.checked },
              });
              console.log(data.checked);
              setUserEnable(data.checked);
            }}
          />

          <Icon
            name="trash alternate"
            color="black"
            size="large"
            onClick={() => handleDelete(_id)}
          />
        </div>
      </Table.Cell>
    </Table.Row>
  );
};
