import gql from "graphql-tag";

export const GET_USERS = gql`
  query usersForAdmin(
    $page: Int
    $limit: Int
    $filter: UserFilter
    $search: String
  ) {
    usersForAdmin(
      page: $page
      limit: $limit
      filter: $filter
      search: $search
    ) {
      payload {
        _id
        mobile
        mobileOTP
        mobileVerified
        email
        emailVerified
        password
        role
        username
        followingUserList
        name
        logo
        placeId
        postCount {
          verified
          unverified
        }
        place {
          _id
          name
          state_id
          state {
            id
            name
            country_id
            country {
              _id
              id
              name
              sortname
              phoneCode
            }
          }
        }
        location {
          type
          coordinates
        }
        geoLocation
        bio
        verified
        verifyReq
        verifiedAs
        banned
        banReason
        enable
        acceptTnC
        createdAt
        updatedAt
      }
      count
      page
      limit
    }
  }
`;
