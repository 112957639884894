import gql from "graphql-tag";

export const EDIT_ADMIN = gql`
  mutation updateAdminUser($userId: String!, $adminUser: AdminUserDetails!) {
    updateAdminUser(userId: $userId, adminUser: $adminUser) {
      payload {
        _id
        name
        email
        password
        role
        logo
        isEnable
        createdAt
        updatedAt
      }
      message
      token
      status
    }
  }
`;
