/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from "react";
import {
  Icon,
  Modal,
  Button,
  Form,
  TextArea,
  Grid,
  GridColumn,
  Dropdown,
} from "semantic-ui-react";
import { toast } from "react-toastify";
import { connect } from "react-redux";
import { GET_ADMIN_USERS } from "../../apollo/query/admins";
import client from "../../apollo";
import { MARK_POST } from "../../apollo/mutation";

class ForwardIcon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
  }

  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  render() {
    const { open } = this.state;
    return (
      <React.Fragment>
        <Icon
          name="mail forward"
          size="large"
          color="blue"
          style={{ cursor: "pointer" }}
          className="ml"
          onClick={this.show}
        />
        {open ? (
          <ForwardModel
            postId={this.props.postId}
            open={open}
            userId={this.props.data.user._id}
            setOpen={this.close}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default connect((state) => ({ data: state }))(ForwardIcon);

const ForwardModel = ({ postId, userId, open, setOpen }) => {
  const [state, setState] = useState({
    users: [],
    markedAdminUserId: "",
    remarks: "",
    isSubmitting: false,
    open,
  });

  useEffect(() => {
    const fetchingAdminUser = async () => {
      try {
        const result = await client.query({ query: GET_ADMIN_USERS });
        setState({ ...state, users: result.data.adminUsers.payload });
      } catch (err) {
        console.log(err.message);
      }
    };
    fetchingAdminUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.open]);

  const handleMarking = async (e) => {
    e.preventDefault();
    try {
      setState({ ...state, isSubmitting: true });
      const result = await client.mutate({
        mutation: MARK_POST,
        variables: {
          markingAdminUserId: userId,
          markedAdminUserId: state.markedAdminUserId,
          postId,
          remarks: state.remarks,
        },
      });
      console.log(result);
      setState({ ...state, isSubmitting: false });
      setOpen();
    } catch (err) {
      const message = err.message.split(":");
      console.log(err.message);
      toast(message[1]);
      setState({ ...state, isSubmitting: false });
    }
  };

  const users = state.users.filter((u) => {
    if (u._id !== userId) {
      u.text = u.name;
      u.value = u._id;
      u.key = u._id;
      return u;
    }
  });

  return (
    <Modal dimmer open={open} onClose={() => setOpen()}>
      <Modal.Content>
        <Modal.Description>
          <Form onSubmit={(e) => handleMarking(e)}>
            <Grid columns={2}>
              <GridColumn>
                <p>Forward to </p>
              </GridColumn>

              <GridColumn>
                <Dropdown
                  placeholder="Name of Team Member (Title)"
                  fluid
                  selection
                  options={users}
                  onChange={(e, data) => {
                    const { value } = data;
                    console.log(value);
                    setState({ ...state, markedAdminUserId: value });
                  }}
                />
              </GridColumn>
            </Grid>
            <br />
            <br />
            <TextArea
              required
              value={state.remarks}
              onChange={(e) => setState({ ...state, remarks: e.target.value })}
              placeholder="Enter your note here for member to read and take action"
            />
            <br />
            <br />
            {/* <p>Is it okay to use this photo?</p> */}
            <Button
              color="green"
              type="submit"
              floated="right"
              disabled={state.markedAdminUserId === "" || state.isSubmitting}
              loading={state.isSubmitting}
            >
              Forward
            </Button>

            <Button
              type="button"
              color="red"
              onClick={() => setOpen()}
              floated="right"
              disabled={state.isSubmitting}
            >
              Cancel
            </Button>
            <br />
            <br />
          </Form>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};
