import React from "react";
import { useQuery } from "react-apollo";
import { Dimmer, Loader, Segment } from "semantic-ui-react";
import { dashBoardDetailQuery } from "../../apollo/query";
import DashBoardCard from "../../components/Card/DashBoardCard";
import PostsList from "../../components/PostsList";

const DashBoardPage = () => {
  const { loading, error, data } = useQuery(dashBoardDetailQuery, {
    variables: { adminId: "123" },
    fetchPolicy: "cache-and-network",
  });

  if (loading && data === undefined) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;
  const {
    categoryCount,
    placeCount,
    unverifiedMobileUserCount,
    unverifiedPostCount,
    verifiedMobileUserCount,
    verifiedPostCount,
    mostLikedPost,
  } = data.dashBoardDetail;
  console.log(data);
  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      <DashBoardCard title="Verified Posts" count={verifiedPostCount} />
      <DashBoardCard title="Unverified Posts" count={unverifiedPostCount} />
      <DashBoardCard
        title="verified Mobile Number Users"
        count={verifiedMobileUserCount}
      />
      <DashBoardCard
        title="Unverified Mobile Number Users"
        count={unverifiedMobileUserCount}
      />
      <DashBoardCard title="Place" count={placeCount} />
      <DashBoardCard title="Category" count={categoryCount} />

      <Segment>
        <h2>Most Liked posts</h2>
        <Segment raised={false} style={{ height: 500, overflowY: "scroll" }}>
          <PostsList posts={mostLikedPost} lightBlue="#fff" />
        </Segment>
      </Segment>
    </div>
  );
};

export default DashBoardPage;
