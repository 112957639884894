import React from "react";
import { useQuery } from "react-apollo";
import { GET_USERS } from "../../apollo/query/users";
import { withRouter } from "react-router-dom";
import RegisterUserTable from "../../components/RegisterUserTable/RegisterUserTable";
import VerificationUserSearchBox from "../../components/VerificationUserSearchBox/VerificationUserSearchBox";
import Placeholder from "../../components/PlaceHolder";
import { Container } from "semantic-ui-react";
import TitleHeader from "../../components/TitleHeader";
import GlobalController from "../../utils/GlobalController";
import InfiniteScroll from "react-infinite-scroll-component";

// import queryString from "query-string";

const RegisteredUsers = (props) => {
  const page = 1;
  const limit = 30;
  const { search } = props.history.location;
  const { mysearch } = GlobalController.queryResolver(search);

  // const { search } = props.history.location;
  // const { mysearch = "", type = "all" } = queryString.parse(search);

  const { error, data, fetchMore } = useQuery(GET_USERS, {
    variables: {
      page,
      limit,
      search: mysearch,
    },
    fetchPolicy: "cache-and-network",
  });
  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;
  const count = data?.usersForAdmin?.count || 0;
  const users = data?.usersForAdmin?.payload || [];
  return (
    <React.Fragment>
      <Container>
        <TitleHeader title="Registered Users" />
        <VerificationUserSearchBox
          searching={mysearch.length > 0}
          goBack={() => props.history.goBack()}
        />

        <InfiniteScroll
          dataLength={users.length}
          next={() => {
            console.log("dcbd");
            fetchMore({
              variables: {
                page: parseInt(users.length / limit) + 1,
                limit,
                search: mysearch,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                const payload = [
                  ...prev.usersForAdmin.payload,
                  ...fetchMoreResult.usersForAdmin.payload,
                ];
                const usersForAdmin = {
                  ...fetchMoreResult.usersForAdmin,
                  payload: payload,
                };
                return Object.assign({}, prev, {
                  usersForAdmin,
                });
              },
            });
          }}
          scrollThreshold={0.5}
          scrollableTarget="scrollingPoint"
          hasMore={count > users.length}
          loader={<Placeholder />}
        >
          <RegisterUserTable users={users} />
        </InfiniteScroll>
      </Container>
    </React.Fragment>
  );
};

export default withRouter(RegisteredUsers);
