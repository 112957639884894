import React from "react";
import PostsList from "../../components/PostsList";
import { useQuery } from "react-apollo";
import { GET_POSTS } from "../../apollo/query";
import Placeholder from "../../components/PlaceHolder";
import { Dimmer, Loader } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import GlobalController from "../../utils/GlobalController";
import SearchBar from "../../components/SearchBar";
import TitleHeader from "../../components/TitleHeader";
import InfiniteScroll from "react-infinite-scroll-component";

const VerifiedPage = (props) => {
  const page = 1;
  const limit = 30;

  const { search } = props.history.location;
  const { start, end, mysearch } = GlobalController.queryResolver(search);

  const filter = {
    from: start,
    to: end,
    verified: false,
    enable: true,
    isMember: true,
  };

  const { loading, error, data, fetchMore } = useQuery(GET_POSTS, {
    variables: { page, limit, filter, search: mysearch },
    fetchPolicy: "cache-and-network",
  });

  if (
    (loading && data === undefined) ||
    (mysearch.length !== 0 && data === undefined)
  ) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;

  const count = data?.postsForAdmin?.count || 0;
  const postsForAdmin = data?.postsForAdmin?.payload || [];
  return (
    <React.Fragment>
      <div>
        <TitleHeader title="Member Posts" count={data.postsForAdmin.count} />
        <SearchBar
          searching={mysearch.length > 0}
          goBack={() => props.history.goBack()}
        />

        <InfiniteScroll
          dataLength={postsForAdmin.length}
          next={() => {
            fetchMore({
              variables: {
                page: parseInt(postsForAdmin.length / limit) + 1,
                limit,
                ...filter,
                search: mysearch,
              },
              updateQuery: (prev, { fetchMoreResult }) => {
                if (!fetchMoreResult) return prev;
                const payload = [
                  ...prev.postsForAdmin.payload,
                  ...fetchMoreResult.postsForAdmin.payload,
                ];
                const postsForAdmin = {
                  ...fetchMoreResult.postsForAdmin,
                  payload: payload,
                };
                return Object.assign({}, prev, {
                  postsForAdmin,
                });
              },
            });
          }}
          scrollThreshold={0.5}
          scrollableTarget="scrollingPoint"
          hasMore={count > postsForAdmin.length}
          loader={<Placeholder />}
        >
          <PostsList
            posts={postsForAdmin}
            lightGreen="#e7f2ea"
            memberPost={true}
          />
        </InfiniteScroll>
      </div>
    </React.Fragment>
  );
};

export default withRouter(VerifiedPage);
