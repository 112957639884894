import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import { withClientState } from "apollo-link-state";
import { ApolloLink, Observable } from "apollo-link";
//
import { store } from "../redux";
import { reset } from "../redux/actions/rootAction";
import { BASE_URL } from "../config/constants";

const cache = new InMemoryCache();

const request = async (operation) => {
  const data = JSON.parse(localStorage.getItem("persist:root"));
  const newToken = data?.token ? data.token.replace(/['"]+/g, "") : null;
  operation.setContext({
    headers: {
      authorization: newToken ? `Bearer ${newToken}` : "",
    },
  });
  //
};

const requestLink = new ApolloLink((operation, forward) => {
  return new Observable((observer) => {
    let handle;
    Promise.resolve(operation)
      .then((oper) => request(oper))
      .then(() => {
        handle = forward(operation).subscribe({
          next: observer.next.bind(observer),
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });
      })
      .catch(observer.error.bind(observer));

    return () => {
      if (handle) handle.unsubscribe();
    };
  });
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        console.log("GraphQL error occurred");
        console.log(graphQLErrors);
        const unAuthenticate = graphQLErrors.find(
          (er) => er.extensions.code === "UNAUTHENTICATED"
        );
        console.log(unAuthenticate);
        if (unAuthenticate) {
          store.dispatch(reset());
        }
      }
      if (networkError) {
        console.log("Network error occurred");
        console.log(networkError);
        if (networkError.result) {
          const unAuthenticate = networkError.result.errors.find(
            (er) => er.extensions.code === "UNAUTHENTICATED"
          );
          console.log(unAuthenticate);
          if (unAuthenticate) {
            store.dispatch(reset());
          }
        }
      }
    }),
    requestLink,
    withClientState({
      defaults: {
        isConnected: true,
      },
      resolvers: {
        Mutation: {
          updateNetworkStatus: (_, { isConnected }, { cache }) => {
            cache.writeData({ data: { isConnected } });
            return null;
          },
        },
      },
      cache,
    }),
    new HttpLink({
      uri: BASE_URL,
    }),
  ]),
  cache,
  connectToDevTools: true,
});

export default client;
