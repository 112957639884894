import gql from 'graphql-tag';

export const VERIFY_LOGIN = gql`
  mutation verifyLogin($mobile: String!, $OTP: Int!) {
    verifyLogin(mobile: $mobile, OTP: $OTP) {
      payload {
        mobile
      }
      token
      message
      status
    }
  }
`;
