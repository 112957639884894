import React from "react";
import { Modal, Button, Form, Icon, Popup } from "semantic-ui-react";
import style from "./delete.module.css";
import client from "../../apollo";
import { DELETE_POST } from "../../apollo/mutation";
import { DELETE_SECRET } from "../../config/constants";
import { toast } from "react-toastify";
import { withRouter } from "react-router-dom";

class DeleteIcon extends React.Component {
  constructor(props) {
    super(props);
    const { postId, status } = this.props;
    this.state = {
      postId,
      status,
      secret: "",
      open: false,
      loading: false,
    };
  }

  handleDelete = async () => {
    const { postId, status, secret } = this.state;
    if (secret === "") {
      toast("Please enter secret");
      return;
    }
    if (secret !== DELETE_SECRET) {
      toast("Secret did not matched");
      return;
    }
    try {
      this.setState({ loading: true });
      const result = await client.mutate({
        mutation: DELETE_POST,
        variables: { postId, enable: !status },
      });
      status
        ? toast("Story has been deleted successfully")
        : toast("You have Un-trash the story");

      console.log(result);
      this.setState({ loading: false, status: !status, open: false });
    } catch (err) {
      console.log(err);
      this.setState({ loading: false });
    }
  };

  show = () => this.setState({ open: true });
  close = () => this.setState({ open: false });

  render() {
    const { open, status, secret } = this.state;
    return (
      <React.Fragment>
        <Popup
          content={`Click to ${status ? "trash" : "untrash"} this post.`}
          // content="ddava"
          trigger={
            <Icon
              name={status ? "trash" : "undo"}
              style={{ cursor: "pointer" }}
              size="large"
              onClick={this.show}
            />
          }
        />

        <Modal dimmer open={open} onClose={this.close}>
          <Modal.Header className={style.textcenter}>
            Are you sure you want to delete this video ?
            <p className={style.notificationdesc}>
              Once you delete this video you won't be able to work on it.
            </p>
          </Modal.Header>
          <Modal.Content>
            <Modal.Description>
              <Form style={{ padding: "0 100px" }}>
                <input
                  placeholder="Enter your secret delete code"
                  required
                  value={secret}
                  type="text"
                  onChange={(e) => this.setState({ secret: e.target.value })}
                />
              </Form>
              {/* <p>Is it okay to use this photo?</p> */}
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <div className={style.btnflex}>
              <Button color="red" onClick={this.handleDelete}>
                {status ? "Trash" : "Untrash"}
              </Button>
              <Button color="green" onClick={this.close}>
                Cancel
              </Button>
            </div>
          </Modal.Actions>
        </Modal>
      </React.Fragment>
    );
  }
}

export default withRouter(DeleteIcon);
