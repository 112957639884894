import React, { useState } from "react";
import { toast } from "react-toastify";
import InfiniteScroll from "react-infinite-scroll-component";
import { PLACES_FOR_ADMIN_QUERY } from "../../apollo/query";
import { useQuery } from "react-apollo";
import Placeholder from "../../components/PlaceHolder";
import {
  Container,
  Input,
  Button,
  Label,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import {
  UPDATE_PLACE_COORDINATES,
  UPDATE_PLACE_GEO_SPHERE,
} from "../../apollo/mutation";
import client from "../../apollo";
import { withRouter } from "react-router-dom";
import GlobalController from "../../utils/GlobalController";
import SearchBar from "../../components/SearchBar";
import TitleHeader from "../../components/TitleHeader";

const PlacePage = (props) => {
  const page = 1;
  const limit = 30;

  const { search } = props.history.location;
  const { mysearch } = GlobalController.queryResolver(search);

  const { loading, error, data, fetchMore } = useQuery(PLACES_FOR_ADMIN_QUERY, {
    variables: { page: page, limit: limit, search: mysearch },
    fetchPolicy: "cache-and-network",
  });

  if (loading && data === undefined) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;
  const count = data?.placesForAdmin?.count || 0;
  const places = data?.placesForAdmin?.payload || [];
  return (
    <Container>
      <TitleHeader
        title="Places"
        buttonTitle="Add City"
        buttonAction={() => props.history.push("city")}
      />
      <SearchBar
        isSearch={true}
        searching={mysearch.length > 0}
        goBack={() => props.history.goBack()}
      />
      <InfiniteScroll
        style={{ overflow: "hidden" }}
        dataLength={places.length}
        next={() => {
          fetchMore({
            variables: { page: page + 1 },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              const payload = [
                ...prev.placesForAdmin.payload,
                ...fetchMoreResult.places.payload,
              ];
              const places = {
                ...fetchMoreResult.placesForAdmin,
                payload: payload,
              };
              return Object.assign({}, prev, {
                places,
              });
            },
          });
        }}
        scrollThreshold={0.5}
        scrollableTarget="scrollingPoint"
        hasMore={count > places.length}
        loader={<Placeholder />}
      >
        {places.map((place, i) => {
          return (
            <div key={i} style={{ display: "flex", marginTop: 20 }}>
              <div style={{ flex: 1, color: "#000" }}>
                {place.name}, {place.state.name}{" "}
                <Label style={{ margin: 5 }}>{place.postCount}</Label>
              </div>
              <EditCoordinates place={place} />
            </div>
          );
        })}
      </InfiniteScroll>
    </Container>
  );
};

export default withRouter(PlacePage);

export const EditCoordinates = ({ place }) => {
  const [coordinates, setCoordinates] = useState(place.location.coordinates);
  const [geoSphere, setGeoSphere] = useState(place.geoSphere);
  const [url, setUrl] = useState("");
  const updatePlace = async () => {
    const long = parseFloat(coordinates[0]);
    const lat = parseFloat(coordinates[1]);
    try {
      const result = await client.mutate({
        mutation: UPDATE_PLACE_COORDINATES,
        variables: { placeId: place._id, long, lat },
      });
      setCoordinates(
        result.data.updatePlaceCoordinates.payload.location.coordinates
      );
      toast(result.data.updatePlaceCoordinates.message);
    } catch (error) {
      console.log(error.message);
      toast(error.message);
    }
  };
  const updatePlaceGeoSphere = async () => {
    try {
      if (geoSphere === place.geoSphere) {
        toast("Geo Sphere is Same");
        return;
      }
      const result = await client.mutate({
        mutation: UPDATE_PLACE_GEO_SPHERE,
        variables: { placeId: place._id, geoSphere: parseInt(geoSphere, 10) },
      });
      setGeoSphere(result.data.updatePlaceGeoSphere.payload.geoSphere);
      toast(result.data.updatePlaceGeoSphere.message);
    } catch (error) {
      console.log(error.message);
      toast(error.message);
    }
  };
  return (
    <div style={{ flex: 3, display: "flex", alignItems: "center" }}>
      <div style={{ color: "#000", fontSize: "1.5rem", flex: 1 }}>
        Long {coordinates[0]} Lat {coordinates[1]}
      </div>
      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        <Input
          type="text"
          value={geoSphere}
          style={{ width: 100 }}
          placeholder="Geo Sphere"
          onChange={(e) => setGeoSphere(e.target.value)}
        />
        <Button onClick={() => updatePlaceGeoSphere()}>Update</Button>
      </div>

      <div style={{ display: "flex", flex: 1, justifyContent: "flex-end" }}>
        <Input
          type="text"
          value={url}
          placeholder="Please enter URL"
          onChange={(e) => {
            const url = e.target.value;
            setUrl(url);
            var expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
            var regex = new RegExp(expression);
            if (url.length > 15) {
              if (url.match(regex)) {
                if (url.indexOf("@") === -1) {
                  toast("Please enter google map URl");
                  return;
                }
                const splitUrl = url.split("@");
                if (url.indexOf("z/") === -1) {
                  toast("Please enter google map URl");
                  return;
                }
                const splitComma = splitUrl[1].split(",");
                setCoordinates([splitComma[1], splitComma[0]]);
              } else {
                toast("Invalid Url");
              }
            }
          }}
        />
        <Button onClick={() => updatePlace()}>Update</Button>
      </div>
    </div>
  );
};
