import gql from "graphql-tag";

export const MARK_POST = gql`
  mutation markingPostToAdminUser(
    $markingAdminUserId: String!
    $markedAdminUserId: String!
    $postId: String!
    $remarks: String!
  ) {
    markingPostToAdminUser(
      markingAdminUserId: $markingAdminUserId
      markedAdminUserId: $markedAdminUserId
      postId: $postId
      remarks: $remarks
    ) {
      status
      token
      message
    }
  }
`;
