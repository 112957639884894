import gql from "graphql-tag";

export const ADD_ADMIN = gql`
  mutation addAdminUser($adminUser: InputAdminUser) {
    addAdminUser(adminUser: $adminUser) {
      payload {
        _id
        name
        email
        password
        role
        logo
        enable
        createdAt
        updatedAt
      }
      message
      token
      status
    }
  }
`;
