import gql from "graphql-tag";

export const UPDATE_PLACE_GEO_SPHERE = gql`
  mutation updatePlaceGeoSphere($placeId: String!, $geoSphere: Int!) {
    updatePlaceGeoSphere(placeId: $placeId, geoSphere: $geoSphere) {
      payload {
        geoSphere
      }
      status
      message
    }
  }
`;
