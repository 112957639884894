import gql from "graphql-tag";

export const MARKS = gql`
  query marks($page: Int, $limit: Int, $filter: MarkFilter, $search: String) {
    marks(page: $page, limit: $limit, filter: $filter, search: $search) {
      payload {
        remarks
        markedUser {
          name
        }
        markingUser {
          name
        }
        post {
          _id
          title
          file {
            name
            type
          }
          description
          tags
          language
          likes
          authorId
          placeId
          place {
            name
          }
          author {
            _id
            name
            mobile
          }
          location {
            type
            coordinates
          }
          isLiked
          verified
          enable
          createdAt
          updatedAt
        }
      }
      count
      limit
      page
    }
  }
`;
