import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

export default Component => {
  const Auth = props => {
    const { token } = props.data;
    const { children } = props;
    if (
      token === null ||
      token === "" ||
      token === undefined
    ) {
      return <Redirect to="/login" />;
    } else {
      return <Component>{children}</Component>;
    }
  };
  return connect(state => ({ data: state }))(Auth);
};
