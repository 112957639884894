import React from "react";
import { Header } from "semantic-ui-react";
import { motion } from "framer-motion";

const TitleHeader = ({ title, count, buttonTitle, buttonAction }) => (
  <Header
    style={{
      color: "#1B91DA",
      display: "flex",
      justifyContent: "space-between",
      borderBottom: "2px solid #1B91DA",
    }}
    size="huge"
  >
    <motion.div animate={{ x: 50 }}>{title}</motion.div>
    {count ? <motion.div>{count}</motion.div> : null}
    {buttonTitle ? (
      <motion.div
        animate={{ x: [50, 0], duration: 0.1 }}
        onClick={buttonAction}
        style={{ cursor: "pointer" }}
      >
        {buttonTitle}
      </motion.div>
    ) : null}
  </Header>
);

export default TitleHeader;
