import React, { useState } from "react";
import {
  Grid,
  Container,
  Icon,
  TextArea,
  Form,
  Checkbox,
  Button,
  Segment,
  Select,
  Loader,
} from "semantic-ui-react";
import { ToastContainer, toast } from "react-toastify";
import ForwardIcon from "../ForwardIcon/ForwardIcon";
import DeleteIcon from "../DeleteIcon/DeleteIcon";
import { motion, AnimatePresence } from "framer-motion";
import {
  UPDATE_POST,
  ATTACH_CATEGORY_TO_POST,
  SEND_NOTIFICATION,
} from "../../apollo/mutation";
import moment from "moment";
import {
  VIEW_POST_VIDEO,
  VIEW_POST_IMAGE,
  TRANS480x270,
} from "../../config/constants";
import { useMutation, useQuery } from "react-apollo";
import { uploadFile } from "../../utils/axios";
import { ADMIN_FILE_UPLOAD } from "../../apollo/mutation";
import MediaAssetViewer from "../MediaAssetViewer";
import PostPublishButton from "../PostPublishButton";
import { GET_CATEGORIES } from "../../apollo/query";
// import { DELETE_SECRET } from "../../config/constants";

const ViewReadStory = ({ post }) => {
  const fileInputRef = React.createRef();
  const [videoUploading, setVideoUploading] = useState(false);
  const [state, setState] = useState(post);
  const [isPublishing, setIsPublishing] = useState(false);
  const [isSending, setSending] = useState(false);
  const {
    _id,
    title,
    file,
    verified,
    createdAt,
    description,
    marks,
    enable,
  } = state;
  const [postVerified] = useState(verified);
  const [status, setStatus] = useState(verified);
  const [handleStoryUpdate] = useMutation(UPDATE_POST, {
    onCompleted: (data) => {
      if (data.editPost) {
        toast("Story updated");
      } else {
        toast("Story not updated");
      }
      setIsPublishing(false);
    },
  });

  const [sendNotification] = useMutation(SEND_NOTIFICATION, {
    onCompleted: (data) => {
      console.log(data);
      if (data.sendNotification) {
        toast("Notification Sent");
      }
      setSending(false);
    },
  });

  const [updateVideo] = useMutation(ADMIN_FILE_UPLOAD, {
    onCompleted: (data) => {
      if (data.adminFileUpload) {
        setState({ ...state, file: data.adminFileUpload.payload.file });
        toast("Video updated");
      } else {
        toast("Video not updated");
      }
      setVideoUploading(false);
    },
  });

  // const handleVerification = async (status) => {
  //   try {
  //     setIsPublishing(true);
  //     const result = await client.mutate({
  //       mutation: VERIFY_POST_MUTATION,
  //       variables: { postId: _id, verify: status },
  //     });
  //     client.cache.reset();
  //     console.log(result.data.verifyPost);
  //     setPostVerified(status);
  //     setIsPublishing(false);
  //     toast(`Story ${status ? "Published" : "Unpublished"}`);
  //   } catch (error) {
  //     setIsPublishing(false);
  //     console.log(error.message);
  //     toast(error.message);
  //   }
  // };

  const handleVideoUpdate = async (file) => {
    try {
      setVideoUploading(true);
      const result = await uploadFile(file);
      const { public_id, format, resource_type } = result.data;
      const fileName = public_id.split("/");
      updateVideo({
        variables: {
          postId: _id,
          fileName: `${fileName[1]}.${format}`,
          fileType: `${resource_type}/${format}`,
        },
      });
    } catch (err) {
      console.log(err);
      setVideoUploading(false);
    }
  };

  const isImage = file.type.includes("image");
  // const [x, cycleX] = useState([0, 0, 0]);
  // const [color, setColor] = useState("#fff");

  // const ShakeIt = () => {
  //   cycleX([0, 20, 0]);
  //   setColor("#ff9191");
  //   setTimeout(() => {
  //     cycleX([0, 0, 0]);
  //     setColor("#fff");
  //   }, 200);
  // };

  return (
    <React.Fragment>
      <Container>
        <ToastContainer />
        <AnimatePresence>
          <motion.div
          // animate={{ x: x }}
          // transition={{ duration: 0.3 }}
          // onTap={() => ShakeIt()}
          >
            <Segment raised>
              <Grid stackable columns={2}>
                <Grid.Row>
                  <Grid.Column width={8}>
                    {/* story number and sharing section  */}
                    <div className="dflex justify-content-between">
                      <div>
                        <p>
                          <b>Story No:</b>
                          {` 545678, ${moment(parseInt(createdAt)).format(
                            "DD MMM, YYYY"
                          )}`}
                        </p>
                      </div>

                      <div className="dflex">
                        <ForwardIcon postId={_id} />
                        {/* <Notes /> */}
                        <DeleteIcon postId={_id} status={enable} />
                        <a
                          href={`${
                            isImage
                              ? VIEW_POST_IMAGE(file.name)
                              : VIEW_POST_VIDEO(file.name, TRANS480x270)
                          }`}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <Icon name="download" size="large" color="black" />
                          &nbsp;
                        </a>

                        <Button
                          color="blue"
                          size="tiny"
                          loading={videoUploading}
                          disabled={videoUploading}
                          as="button"
                          content="Upload"
                          type="button"
                          onClick={() => fileInputRef.current.click()}
                        />
                      </div>
                    </div>
                    <br />
                    {/* story number and sharing section end */}

                    {/* user video section  */}

                    <MediaAssetViewer file={file} />

                    <br />
                    {/* user video section end  */}
                    <Form>
                      <Form.Field>
                        <label>Title:</label>
                        <TextArea
                          placeholder="Enter title of news here"
                          value={title}
                          onChange={(e) =>
                            setState({ ...state, title: e.target.value })
                          }
                        />
                      </Form.Field>
                      <React.Fragment>
                        <input
                          ref={fileInputRef}
                          name="file"
                          type="file"
                          accept="video/*, image/*"
                          hidden
                          onChange={(e) => handleVideoUpdate(e.target.files[0])}
                        />
                      </React.Fragment>
                      {/* <a
                    className="ui blue basic button"
                    href={`${isImage ? VIEW_POST_IMAGE : VIEW_POST_VIDEO}${file.name}`}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    Download Media
                  </a> */}
                    </Form>

                    <EditableCategory post={post} />
                    <span style={{}}>
                      <MarkedList marks={marks} />
                    </span>
                  </Grid.Column>
                  <Grid.Column width={8}>
                    <div className="dflex justify-content-between">
                      <div>
                        <Checkbox /> &nbsp; Do not send Notification
                      </div>
                      <br />
                    </div>
                    <br />
                    <Form>
                      <Form.Field>
                        <label>Description:</label>
                        <TextArea
                          rows={24}
                          placeholder="Enter the text news here ..."
                          value={description}
                          onChange={(e) =>
                            setState({ ...state, description: e.target.value })
                          }
                        />
                      </Form.Field>
                      <div className="dflex justify-content-between wrap">
                        <Button
                          basic
                          color="blue"
                          className="radius"
                          content="Notification"
                          loading={isSending}
                          disabled={isSending}
                          onClick={() => {
                            setSending(true);
                            sendNotification({
                              variables: { postId: post._id },
                            });
                          }}
                        />
                        <Button
                          basic
                          color="blue"
                          className="radius"
                          content="Update Story"
                          disabled={isPublishing}
                          loading={isPublishing}
                          onClick={() =>
                            handleStoryUpdate({
                              variables: {
                                postId: _id,
                                post: {
                                  title: state.title,
                                  description: state.description,
                                },
                              },
                            })
                          }
                        />

                        <PostPublishButton
                          postId={_id}
                          status={status}
                          setStatus={(change) => setStatus(change)}
                          publishButton={true}
                          isPublishing={isPublishing}
                          postVerified={postVerified}
                        />
                      </div>
                    </Form>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Segment>
          </motion.div>
        </AnimatePresence>
      </Container>
    </React.Fragment>
  );
};

export default ViewReadStory;

const MarkedList = ({ marks }) => {
  return marks.map((mark, i) => {
    return (
      <div key={mark._id}>
        <p>{`${i + 1}). ${mark.markingUser.name} marked to ${
          mark.markedUser.name
        }`}</p>
        <p>{`Remarks: ${mark.remarks}`}</p>
      </div>
    );
  });
};

const EditableCategory = ({ post }) => {
  const [isEditing, setEditing] = useState(false);
  return (
    <React.Fragment>
      {isEditing ? (
        <CategorySelector post={post} />
      ) : (
        <div>
          <>
            <div style={{ display: "flex" }}>
              Category:-&nbsp;
              <div style={{ flex: 1, color: "#000" }}>{post.category.name}</div>
              <div style={{ flex: 1 }}>
                <Icon
                  name="pencil alternate"
                  color="blue"
                  style={{ cursor: "pointer" }}
                  onClick={() => setEditing(true)}
                />
              </div>
            </div>
          </>
        </div>
      )}
    </React.Fragment>
  );
};

const CategorySelector = ({ post }) => {
  const [categoryId, setCategoryId] = useState("");

  const [attachCategoryToPost] = useMutation(ATTACH_CATEGORY_TO_POST, {
    onCompleted: (data) => {
      if (data.attachCategoriesToPost) {
        toast(
          `Updated To ${data.attachCategoriesToPost.payload.category.name}`
        );
      }
    },
  });

  const { loading, error, data } = useQuery(GET_CATEGORIES, {
    variables: { page: 1, limit: 3000 },
  });
  if (loading)
    return (
      <Segment>
        <Loader active />
      </Segment>
    );
  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;
  const categories = data.categories.payload.map((category) => {
    category.value = category._id;
    category.key = category._id;
    category.text = category.name;
    return category;
  });

  return (
    <React.Fragment>
      <Select
        placeholder="Select your Category"
        options={categories}
        onChange={(e, data) => {
          setCategoryId(data.value);
        }}
      />

      <Button
        basic
        color="blue"
        content="Save"
        onClick={() => {
          if (categoryId === "") {
            toast("Please Select Category");
            return;
          }

          attachCategoryToPost({
            variables: { postId: post._id, categoryIds: [categoryId] },
          });
        }}
        style={{ margin: 8 }}
      />
    </React.Fragment>
  );
};
