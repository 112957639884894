import React from "react";
// import { Responsive } from "semantic-ui-react";
// import SearchBoxMobile from "./SearchBoxMobile";
import SearchBoxDesktop from "./SearchBoxDesktop";

const SearchBox = ({ isSearch }) => {
  return (
    <div>
      {/* <Responsive {...Responsive.onlyMobile}>
        <SearchBoxMobile />
      </Responsive>
      <Responsive minWidth={Responsive.onlyTablet.minWidth}> */}
      <SearchBoxDesktop isSearch={isSearch} />
      {/* </Responsive> */}
    </div>
  );
};

export default SearchBox;
