/* eslint-disable react/destructuring-assignment */
import React, { Component } from "react";
import { Input, Menu, Icon, Grid } from "semantic-ui-react";
import "rc-datepicker/lib/style.css";
import queryString from "query-string";
import { Link, withRouter } from "react-router-dom";
import { DatePickerInput } from "rc-datepicker";
import moment from "moment";
import { motion } from "framer-motion";

class SearchBoxDesktop extends Component {
  constructor(props) {
    super(props);
    const { history } = this.props;
    const { search } = history.location;
    const { from, to } = queryString.parse(search);
    this.state = {
      searchVal: "",
      from: from || moment().format("YYYY-MM-DD"),
      to: to || moment().format("YYYY-MM-DD"),
    };
  }

  handleKeyPress = (e) => {
    const { searchVal } = this.state;
    const { history } = this.props;
    const { pathname } = history.location;
    if (e.key === "Enter") {
      history.push(`${pathname}?type=search&mysearch=${searchVal}`);
    }
  };

  handleSearchInput = (e) => {
    this.setState({ searchVal: e.target.value });
  };

  render() {
    const { history, isSearch } = this.props;
    const { search, pathname } = history.location;
    const { period } = queryString.parse(search);
    const { searchVal, from, to } = this.state;
    return (
      <Grid stackable columns={2}>
        <Grid.Column style={{ display: "flex", justifyContent: "flex-start" }}>
          {isSearch ? (
            <motion.div whileHover={{ scale: 1.3 }} style={{ width: "100%" }}>
              <Input
                style={{
                  flexGrow: 1,
                  color: "#1B91DA",
                  marginRight: 5,
                  height: 36.5,
                }}
                fluid
                icon="search"
                iconPosition="left"
                placeholder="Search..."
                value={searchVal}
                onKeyPress={this.handleKeyPress}
                onChange={this.handleSearchInput}
              />
            </motion.div>
          ) : null}
        </Grid.Column>
        <Grid.Column>
          <Menu secondary>
            <p style={{ marginTop: 9.5, color: "#000" }}>From</p>
            <motion.div
              whileHover={{ scale: 1.3 }}
              style={{ width: "100%", zIndex: 10 }}
            >
              <DatePickerInput
                locale="en"
                style={{ width: 150, marginLeft: 5, zIndex: 10 }}
                displayFormat="DD/MM/YYYY"
                returnFormat="YYYY-MM-DD"
                onChange={(e, data) => this.setState({ from: data, to: data })}
                value={from}
                minDate="2019-01-01"
                maxDate={moment().format("YYYY-MM-DD")}
              />
            </motion.div>
            <p style={{ marginTop: 9.5, color: "#000" }}>To</p>
            <motion.div
              whileHover={{ scale: 1.3 }}
              style={{ width: "100%", zIndex: 10 }}
            >
              <DatePickerInput
                locale="en"
                style={{ width: 150, marginLeft: 5, zIndex: 10 }}
                displayFormat="DD/MM/YYYY"
                returnFormat="YYYY-MM-DD"
                onChange={(e, data) => this.setState({ to: data })}
                value={to}
                minDate={from}
                maxDate={moment().format("YYYY-MM-DD")}
              />
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.3 }}
              style={{ width: "100%", zIndex: 10 }}
            >
              <Icon
                style={{ color: "#1478c2", margin: 8 }}
                size="large"
                name="search"
                onClick={() =>
                  history.push(`${pathname}?from=${from}&to=${to}`)
                }
              />
            </motion.div>
            <Menu.Menu position="right">
              <motion.div
                whileHover={{ scale: 1.3 }}
                style={{ width: "100%", zIndex: 10 }}
              >
                <Menu.Item
                  name="Latest"
                  as={Link}
                  to={`${pathname}?period=latest`}
                  active={period === "latest"}
                  style={
                    period === "latest"
                      ? { backgroundColor: "#2085CF", color: "#FFFFFF" }
                      : null
                  }
                />
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.3 }}
                style={{ width: "100%", zIndex: 10 }}
              >
                <Menu.Item
                  name="Today"
                  as={Link}
                  to={`${pathname}?period=day`}
                  active={period === "day"}
                  style={
                    period === "day"
                      ? { backgroundColor: "#2085CF", color: "#FFFFFF" }
                      : null
                  }
                />
              </motion.div>
            </Menu.Menu>
          </Menu>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(SearchBoxDesktop);
