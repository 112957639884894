import React from "react";
// Toast container and CSS imported.
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Higher Order Component for auth check.
import Auth from "../Auth";
// Dashboard components imported.
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";

const DashboardLayout = (props) => {
  return (
    <React.Fragment>
      <Navbar />
      <div style={{ marginTop: 70 }}>
        <Container>{props.children}</Container>
      </div>
      <Footer />
      <ToastContainer />
    </React.Fragment>
  );
};

export default Auth(withRouter(DashboardLayout));
