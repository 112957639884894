import { createStore, applyMiddleware } from "redux";
//
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
//
import thunk from "redux-thunk";
import logger from "redux-logger";
//
import rootReducer from "./reducers";

let middleware = [thunk];

if (process.env.NODE_ENV !== "production") {
  middleware = [...middleware, logger];
}

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export let store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);

export let persistor = persistStore(store);
