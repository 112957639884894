/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Button, Form, Grid, Image } from "semantic-ui-react";
import client from "../../apollo";
import { ADMIN_LOGIN_MUTATION } from "../../apollo/mutation";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { setToken, setUser } from "../../redux/actions/rootAction";
import { motion } from "framer-motion";

class LoginPage extends Component {
  constructor(props) {
    super(props);
    this.login = this.login.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.state = {
      email: "",
      password: "",
      error: false,
    };
  }

  async login(e) {
    e.preventDefault();
    try {
      const { email, password } = this.state;
      email.toLocaleLowerCase();
      if (email === "") {
        toast("Email is required");
        return;
      }
      if (password === "") {
        toast("Password is required");
        return;
      }
      const login = await client.mutate({
        mutation: ADMIN_LOGIN_MUTATION,
        variables: { email, password },
      });
      const message = login.data.adminLogin.message.split(":");
      if (login.data.adminLogin.status === 200) {
        toast(message[1]);
        this.props.setToken(login.data.adminLogin.token);
        this.props.setUser(login.data.adminLogin.payload);
        setTimeout(() => {
          this.props.history.push("/broadcast");
        }, 300);
      } else {
        toast(message[1]);
        toast(login.data.adminLogin.message);
      }
    } catch (error) {
      const message = error.message.split(":");
      console.log(error.message);
      toast(message[1]);

      this.setState({
        error: true,
      });
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  render() {
    const { error } = this.state;
    return (
      <React.Fragment>
        <ToastContainer />
        <Grid textAlign="center" verticalAlign="middle" className="login-bg">
          <Grid.Column style={{ maxWidth: 450 }}>
            <Image
              src="/A1.png"
              style={{
                width: 120,
                height: 120,
                margin: "0 auto",
                // border: "1px solid red",
                // background: "#fff",
              }}
            />
            {/* <Header as="h2" color="black" textAlign="center">
              ADMIN LOGIN
            </Header> */}
            <Form size="large">
              <motion.div
                whileHover={{ scale: 1.3 }}
                style={{ width: "100%", margin: 10 }}
              >
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="E-mail address"
                  name="email"
                  onChange={this.handleChange}
                  value={this.state.email}
                />
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.3 }}
                style={{ width: "100%", margin: 10 }}
              >
                <Form.Input
                  name="password"
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Password"
                  type="password"
                  onChange={this.handleChange}
                  value={this.state.password}
                  // style={styles.shake}
                  style={{
                    borderRadius: error ? "8px" : null,
                    border: error ? "2px solid #dc6d6d" : null,
                  }}
                />
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.3 }}
                whileTap={{ scale: 1.0 }}
                style={{ width: "100%", margin: 10 }}
              >
                <Button
                  color="yellow"
                  size="large"
                  style={{ color: "#000" }}
                  onClick={this.login}
                >
                  Login
                </Button>
              </motion.div>
            </Form>
            {/* <Message floating>
                <Button
                  color="yellow"
                  size="large"
                  style={{ color: "#000" }}
                  onClick={this.login}
                >
                  Login
                </Button>
              </Form>
              {/* <Message floating>
              <a href="#">Register</a>
            </Message> */}
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}

export default withRouter(
  connect(
    (state) => ({}),
    (dispatch) => ({
      setToken: (payload) => dispatch(setToken(payload)),
      setUser: (payload) => dispatch(setUser(payload)),
    })
  )(LoginPage)
);
