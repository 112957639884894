import gql from "graphql-tag";

export const POSTS_FOR_ADMIN_QUERY = gql`
  query postsForAdmin(
    $page: Int
    $limit: Int
    $filter: PostFilter
    $search: String
  ) {
    postsForAdmin(
      page: $page
      limit: $limit
      filter: $filter
      search: $search
    ) {
      payload {
        _id
        title
        likes
        author {
          _id
          mobile
        }
        verified
        likes
        file {
          name
          type
        }
        description
        verified
        createdAt
        updatedAt
        place {
          name
          location {
            coordinates
          }
          state {
            name
          }
        }
      }
      count
      limit
      page
    }
  }
`;
