import React from "react";
import { Segment, Placeholder } from "semantic-ui-react";

export const PlaceHolder = () => (
  <React.Fragment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
    <Segment
      style={{
        border: "0.5px solid #d4d4d5",
        margin: "10px 2px 10px 2px",
      }}
    >
      <Placeholder>
        <Placeholder.Header image>
          <Placeholder.Line />
          <Placeholder.Line />
        </Placeholder.Header>
        <Placeholder.Paragraph>
          <Placeholder.Line length="medium" />
        </Placeholder.Paragraph>
      </Placeholder>
    </Segment>
  </React.Fragment>
);
