import React, { Component } from "react";
import {
  Map,
  Marker,
  GoogleApiWrapper,
  Circle,
  InfoWindow,
} from "google-maps-react";
import client from "../../apollo";
import { PLACES_QUERY } from "../../apollo/query";

class MapPage extends Component {
  state = {
    places: [],
    showingInfoWindow: false,
    activeMarker: {},
    selectedPlace: null,
  };

  async componentDidMount() {
    const result = await client.query({
      query: PLACES_QUERY,
      variables: { page: 1, limit: 3000 },
    });
    this.setState({ places: result.data.places.payload });
  }

  onMarkerClick = (props, marker, e) =>
    this.setState({
      selectedPlace: props,
      activeMarker: marker,
      showingInfoWindow: true,
    });

  onMapClicked = (props) => {
    if (this.state.showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      });
    }
  };

  render() {
    const { places, selectedPlace } = this.state;
    if (places.length === 0) {
      return <h1>Loading.....</h1>;
    }
    return (
      <>
        <Map
          initialCenter={{
            lat: 21.2787,
            lng: 81.8661,
          }}
          onClick={this.onMapClicked}
          google={this.props.google}
          style={{ width: "100%", height: "100%", position: "relative" }}
          zoom={8}
        >
          {places.map((place, i) => {
            const { coordinates } = place.location;
            const coords = { lat: coordinates[1], lng: coordinates[0] };
            return (
              <Marker
                onClick={this.onMarkerClick}
                key={i}
                title={place.name}
                position={coords}
                place={place}
              />
            );
          })}
          {places.map((place, i) => {
            const { coordinates } = place.location;
            const coords = { lat: coordinates[1], lng: coordinates[0] };
            const radius = place.geoSphere * 1609.34;
            return (
              <Circle
                key={i}
                radius={radius}
                center={coords}
                strokeColor="transparent"
                strokeOpacity={0}
                strokeWeight={5}
                fillColor="red"
                fillOpacity={0.3}
              />
            );
          })}
          {selectedPlace === null ? null : (
            <InfoWindow
              marker={this.state.activeMarker}
              visible={this.state.showingInfoWindow}
            >
              <div>
                <h1>
                  {selectedPlace.place.name} {selectedPlace.place._id} has Total{" "}
                  {selectedPlace.place.postCount} Posts
                </h1>
              </div>
            </InfoWindow>
          )}
        </Map>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAM3SziJh-zu0ulKiTpIEh0uGmnUjmq8_c",
})(MapPage);
