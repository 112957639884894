import gql from "graphql-tag";

export const dashBoardDetailQuery = gql`
  query dashBoardDetail($adminId: String!) {
    dashBoardDetail(adminId: $adminId) {
      verifiedPostCount
      unverifiedPostCount
      verifiedMobileUserCount
      unverifiedMobileUserCount
      placeCount
      categoryCount
      mostLikedPost {
        _id
        title
        likes
        author {
          _id
          mobile
        }
        verified
        likes
        file {
          name
          type
        }
        description
        verified
        createdAt
        updatedAt
        place {
          name
          location {
            coordinates
          }
          state {
            name
          }
        }
      }
    }
  }
`;
