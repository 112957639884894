import React from "react";
import ViewReadStory from "../../components/ViewReadStory/ViewReadStory";
import { useQuery } from "react-apollo";
import { GET_POST } from "../../apollo/query";
import { useParams } from "react-router-dom";
import { Dimmer, Loader } from "semantic-ui-react";

const ViewReadStoryPage = () => {
  let { postId } = useParams();
  const { loading, error, data } = useQuery(GET_POST, {
    variables: { postId },
  });

  if (loading)
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;
  const { postForUser } = data;
  return (
    <React.Fragment>
      <ViewReadStory post={postForUser.payload} />
    </React.Fragment>
  );
};

export default ViewReadStoryPage;
