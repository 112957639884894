import React, { useState } from "react";
import { Container, Icon } from "semantic-ui-react";
import style from "./verified.module.css";
import { GET_USERS } from "../../apollo/query";
import { useQuery } from "react-apollo";
import Placeholder from "../../components/PlaceHolder";

const VerifiedUserRequest = () => {
  const [filters] = useState({
    page: 1,
    limit: 30,
    search: "",
    verified: true,
  });

  const { loading, error, data } = useQuery(GET_USERS, {
    variables: { ...filters },
  });

  if (loading)
    return (
      <Container>
        <Placeholder />
      </Container>
    );

  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;

  const { payload } = data.usersForAdmin;

  return (
    <React.Fragment>
      <Container style={{ marginTop: 40 }}>
        <RequestedUserList users={payload} />
      </Container>
    </React.Fragment>
  );
};

export default VerifiedUserRequest;

const RequestedUserList = ({ users }) => {
  return (
    <div className="dflex" style={{ flexWrap: "wrap" }}>
      {users.map((user, i) => (
        <RequestedUserListItem key={i} user={user} />
      ))}
    </div>
  );
};

const RequestedUserListItem = ({ user }) => {
  const { place, postCount } = user;
  return (
    <div className={style.flexstart}>
      <h2 className={style.title}>
        {user.mobile ? user.mobile : user.username}
      </h2>
      <div className={style.citytext}>
        {place?.name ? place.name : "Not Available"}
      </div>
      <p className={style.number}>{postCount.verified} Verified</p>
      <p className={style.number}>{postCount.unverified} Unverified</p>
      <div className={style.approvedtext}>News Approved</div>
      <div style={{ float: "right", cursor: "pointer" }}>
        <Icon name="edit" size="large" inverted />
      </div>
    </div>
  );
};
