import React from "react";
import { Grid, Menu, Input, Container, Button } from "semantic-ui-react";
import { withRouter, Link } from "react-router-dom";
import queryString from "query-string";

class VerificationUserSearchBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: "Pending",
      searchVal: "",
    };
  }

  handleKeyPress = (e) => {
    const { searchVal } = this.state;
    const { history } = this.props;
    const { pathname } = history.location;
    if (e.key === "Enter") {
      history.push(`${pathname}?type=search&mysearch=${searchVal}`);
    }
  };

  handleSearchInput = (e) => {
    this.setState({ searchVal: e.target.value });
  };

  handleItemClick = (name) => this.setState({ activeItem: name });

  render() {
    const { history, searching, goBack } = this.props;
    const { search, pathname } = history.location;
    const { searchVal } = this.state;
    const { user } = queryString.parse(search);
    console.log(user);

    if (searching) return <Button onClick={() => goBack()}>Back</Button>;
    return (
      <React.Fragment>
        <Container style={{ marginTop: 20 }}>
          <Grid stackable columns={2}>
            <Grid.Column width={12}>
              <Input
                style={{
                  flexGrow: 1,
                  color: "#1478c2",
                }}
                fluid
                icon="search"
                iconPosition="left"
                placeholder="Search..."
                value={searchVal}
                onKeyPress={this.handleKeyPress}
                onChange={this.handleSearchInput}
              />
            </Grid.Column>
            <Grid.Column width={4}>
              <Menu secondary widths={4} size="small">
                <Menu.Item
                  name="All"
                  primary
                  as={Link}
                  to={`${pathname}?user=All`}
                  active={user === "All"}
                  style={
                    user === "All"
                      ? { backgroundColor: "#2085CF", color: "#FFFFFF" }
                      : null
                  }
                  // onClick={(e, data) => {
                  //   this.handleItemClick(data.name);
                  //   fetchMore({ filter: {} });
                  // }}
                />
                <Menu.Item
                  name="Pending"
                  primary
                  as={Link}
                  to={`${pathname}?user=Pending`}
                  active={user === "Pending"}
                  style={
                    user === "Pending"
                      ? { backgroundColor: "#2085CF", color: "#FFFFFF" }
                      : null
                  }
                  // onClick={(e, data) => {
                  //   this.handleItemClick(data.name);
                  //   fetchMore({ filter: { verified: false } });
                  // }}
                />
                <Menu.Item
                  name="Verified"
                  active={user === "Verified"}
                  as={Link}
                  to={`${pathname}?user=Verified`}
                  style={
                    user === "Verified"
                      ? { backgroundColor: "#2085CF", color: "#FFFFFF" }
                      : null
                  }
                  // onClick={(e, data) => {
                  //   this.handleItemClick(data.name);
                  //      fetchMore({ filter: { verified: true } });
                  // }}
                />
              </Menu>
            </Grid.Column>
          </Grid>
        </Container>
      </React.Fragment>
    );
  }
}

export default withRouter(VerificationUserSearchBox);
