import React, { useState } from "react";
import { GET_STATES } from "../../apollo/query";
import { useQuery } from "react-apollo";
import { useMutation } from "react-apollo";
import { ADD_PLACES } from "../../apollo/mutation";
import { Form, Container, Dimmer, Loader } from "semantic-ui-react";
import { toast } from "react-toastify";

const CreateCity = (props) => {
  const [place, setPlace] = useState({
    name: "",
    stateId: "",
    long: "",
    lat: "",
    mapLink: "",
  });

  const { loading, data } = useQuery(GET_STATES);

  const [createPlace] = useMutation(ADD_PLACES, {
    onCompleted: (data) => {
      // console.log(data);
      toast.success("Successfully added");
      setPlace({ name: "", stateId: "", long: "", lat: "", mapLink: "" });
      props.history.goBack();
    },
  });

  const onSubmit = (e) => {
    e.preventDefault();
    const { name, stateId, long, lat } = place;
    const longitude = parseFloat(long);
    const latitude = parseFloat(lat);

    createPlace({
      variables: {
        name: name,
        stateId: stateId,
        long: longitude,
        lat: latitude,
      },
    });
  };

  if (loading) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  const handleMapLinkChange = (e) => {
    const url = e.target.value;
    setPlace({ ...place, mapLink: url });
    var expression = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
    var regex = new RegExp(expression);
    if (url.length > 15) {
      if (url.match(regex)) {
        if (url.indexOf("@") === -1) {
          toast.error("Please enter google map URl");
          return;
        }
        const splitUrl = url.split("@");
        if (url.indexOf("z/") === -1) {
          toast.error("Please enter google map URl");
          return;
        }
        const splitComma = splitUrl[1].split(",");
        setPlace({
          ...place,
          long: splitComma[1],
          lat: splitComma[0],
          mapLink: url,
        });
      } else {
        toast.error("Invalid Url");
      }
    }
  };

  const handleChange = (e) => {
    setPlace({
      ...place,
      [e.target.name]: e.target.value,
    });
  };

  const states = data?.states?.payload || [];
  return (
    <React.Fragment>
      <Container>
        <Form onSubmit={onSubmit}>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="City Name"
              placeholder="Enter City Name"
              name="name"
              value={place.name}
              onChange={handleChange}
              required
            />
            <Form.Select
              fluid
              label="Select State"
              options={states.map((state) => ({
                key: state.id,
                text: state.name,
                value: state.id,
              }))}
              placeholder="Please Select State"
              search
              name="stateId"
              onChange={(e, data) =>
                setPlace({ ...place, stateId: data.value })
              }
              required
              value={place.stateId}
            />
          </Form.Group>
          <Form.Input
            fluid
            label="Google Map Link"
            placeholder="Enter Google Map Link"
            name="mapLink"
            value={place.mapLink}
            onChange={handleMapLinkChange}
            required
          />
          <h3>
            long : {place.long} , Lat: {place.lat}
          </h3>
          <Form.Button>Submit</Form.Button>
        </Form>
      </Container>
    </React.Fragment>
  );
};

export default CreateCity;
