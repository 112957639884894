import gql from "graphql-tag";

export const DELETE_ADMIN_USER = gql`
  mutation deleteAdminUser($adminUserId: String!) {
    deleteAdminUser(adminUserId: $adminUserId) {
      payload {
        _id
        name
        email
        password
        role
        logo
        isEnable
        createdAt
        updatedAt
      }
      message
      token
      status
    }
  }
`;
