import gql from "graphql-tag";

export const UPDATE_POST = gql`
  mutation editPost($postId: String!, $post: EditPost!) {
    editPost(postId: $postId, post: $post) {
      message
      status
    }
  }
`;
