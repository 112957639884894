import gql from "graphql-tag";

export const UPDATE_PLACE_COORDINATES = gql`
  mutation updatePlaceCoordinates($placeId: String!, $lat: Float!, $long: Float!) {
    updatePlaceCoordinates(placeId: $placeId, lat: $lat, long: $long) {
      payload {
        name
        location {
          coordinates
        }
      }
      status
      message
    }
  }
`;
