/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from "react";
import { MARKS } from "../../apollo/query";
import style from "./user.module.css";
import { useQuery } from "react-apollo";
import { Item, Segment, Dimmer, Loader } from "semantic-ui-react";
import MediaIcon from "../../components/MediaIcon";
import PostPublishButton from "../../components/PostPublishButton";
import { Link } from "react-router-dom";
import Placeholder from "../../components/PlaceHolder";
import moment from "moment";
import { connect } from "react-redux";
import GlobalController from "../../utils/GlobalController";
import { withRouter } from "react-router-dom";
import TitleHeader from "../../components/TitleHeader";
import SearchBar from "../../components/SearchBar";
import InfiniteScroll from "react-infinite-scroll-component";
import ForwardIcon from "../../components/ForwardIcon/ForwardIcon";

const MarkedPage = (props) => {
  const page = 1;
  const limit = 30;

  const { search } = props.history.location;
  const { start, end, mysearch } = GlobalController.queryResolver(search);

  const filter = {
    markedAdminUserId: props.data.user._id,
    from: start,
    to: end,
  };

  const { loading, error, data, fetchMore } = useQuery(MARKS, {
    variables: { page, limit, filter, search: mysearch },
    fetchPolicy: "cache-and-network",
  });

  if (loading && data === undefined) {
    return (
      <Dimmer active>
        <Loader />
      </Dimmer>
    );
  }

  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;

  const count = data?.marks?.count || 0;
  const marks = data?.marks?.payload || [];

  return (
    <div>
      <TitleHeader title="Marked Posts" count={data.marks.count} />
      <SearchBar
        searching={mysearch.length > 0}
        goBack={() => props.history.goBack()}
      />
      <InfiniteScroll
        dataLength={marks.length}
        next={() => {
          fetchMore({
            variables: {
              page: parseInt(marks.length / limit) + 1,
              limit,
              ...filter,
              search: mysearch,
            },
            updateQuery: (prev, { fetchMoreResult }) => {
              if (!fetchMoreResult) return prev;
              const payload = [
                ...prev.marks.payload,
                ...fetchMoreResult.marks.payload,
              ];
              const marks = {
                ...fetchMoreResult.marks,
                payload: payload,
              };
              return Object.assign({}, prev, {
                marks,
              });
            },
          });
        }}
        scrollThreshold={0.5}
        scrollableTarget="scrollingPoint"
        hasMore={count > marks.length}
        loader={<Placeholder />}
      />
      {marks.map((mark, i) => (
        <MarkPostListItem mark={mark} key={i} />
      ))}
    </div>
  );
};

export default connect((state) => ({ data: state }))(withRouter(MarkedPage));

export const MarkPostListItem = ({ mark }) => {
  const { _id, file, title, author, verified, createdAt } = mark.post;
  const { markingUser, markedUser } = mark;
  const [status, setStatus] = useState(verified);
  return (
    <Segment raised color="blue" className={style.topbottompadding}>
      <Item.Group divided>
        <Item>
          {/* user image */}
          {/* <video
            src={`${VIEW_POST_VIDEO}${file.name}`}
            className="circular"
            alt="dd"
            style={{ width: 50, height: 50 }}
          /> */}
          {/* user image end */}

          <Item.Content className="ml">
            {/* user story title  */}
            <Item.Header>
              <div className={style.storytitle}>
                <Link to={Link} to={`/post/${_id}`}>
                  {title}
                </Link>
                <ForwardIcon postId={_id} />
              </div>

              <div
                className={style.publishedtext}
              >{`${markingUser.name} : ${markedUser.name}`}</div>
              <div className={style.publishedtext}>{`Created at:  ${moment(
                new Date(parseInt(createdAt))
              ).format("lll")}`}</div>

              <div
                className={style.publishedtext}
              >{`Remarks: ${mark.remarks}`}</div>
            </Item.Header>
            {/* <ForwardIcon postId={_id} /> */}
            {/* user story title  */}

            <div className={style.togglefloat}>
              {/* video publish un publish modal button  */}
              <PostPublishButton
                postId={_id}
                status={status}
                setStatus={(change) => setStatus(change)}
              />

              {/* video publish un publish modal button end */}

              {/* user video popup icon  */}
              <MediaIcon file={file} title={title} />
              {/* user video popup icon end */}
            </div>

            <Item.Description>
              <div style={{ marginTop: -10 }}>
                {/* {tags.map((tag, i) => (
                  <p key={i} className={style.hastagblue}>
                    {tag}
                  </p>
                ))} */}
              </div>
            </Item.Description>

            <Item.Extra>
              <div style={{ marginTop: -10 }}>
                <p className={style.publishedtext}>
                  {`Published By : 
                  
                  ${
                    author?.verified ? (
                      <img
                        src="/verified-user-icon.png"
                        alt="verified-icon"
                        style={{ width: 15 }}
                      />
                    ) : (
                      ""
                    )
                  }
                  ${author?.name ? author.name : "Not Available"} ${moment(
                    parseInt(createdAt)
                  ).format("DD MMM, YYYY")}`}
                </p>
              </div>
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </Segment>
  );
};
