import React from "react";
import { Item } from "semantic-ui-react";
import moment from "moment";
import style from "./user.module.css";
// import NotificationBell from "../NotificationBell/NotificationBell";
import MediaIcon from "../MediaIcon";
// import PostPublishButton from "../PostPublishButton";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";
import ForwardIcon from "../ForwardIcon/ForwardIcon";

const list = {
  visible: { opacity: 1 },
  hidden: { opacity: 0 },
};

// const item = {
//   visible: { opacity: 1, x: 0 },
//   hidden: { opacity: 0, x: -100 },
// };

const BroadcastPostsList = ({ posts, lightBlue }) => {
  return (
    <React.Fragment>
      <motion.ul initial="hidden" animate="visible" variants={list}>
        {posts.map((post, i) => (
          <motion.li
            key={i}
            whileHover={{ backgroundColor: "#eaeff2" }}
            style={{
              backgroundColor: "#F5F8FA",
              borderRadius: 5,
              margin: 10,
            }}
          >
            <BroadcastPostsListItem
              number
              key={i}
              post={post}
              lightBlue={lightBlue}
            />
          </motion.li>
        ))}
      </motion.ul>
    </React.Fragment>
  );
};

export default BroadcastPostsList;

export const BroadcastPostsListItem = ({ post, lightBlue }) => {
  const { _id, file, title, author, createdAt, place } = post;
  return (
    <div className={style.topbottompadding}>
      <Item.Group divided>
        <Item>
          <motion.div
            animate={{
              scale: [1, 1.2, 1.2, 1, 1],
            }}
          >
            <MediaIcon avatar file={file} title={title} />
          </motion.div>

          <Item.Content className="ml">
            <Item.Header as={Link} to={`/post/${_id}`} className="pt14">
              {title}
            </Item.Header>
            <ForwardIcon postId={_id} />
            <div
              className={style.togglefloat}
              style={{ display: "flex", flexDirection: "column" }}
            >
              {moment(new Date(parseInt(createdAt))).format("lll")}
              <p style={{ fontSize: "1.2rem" }}>
                {place.name} {place.state.name}
              </p>
            </div>

            <Item.Description>
              <div style={{ marginTop: -10 }}></div>
            </Item.Description>

            <Item.Extra>
              <div>
                <p
                  className="mt20"
                  style={{ fontSize: "1.2rem", letterSpacing: 0.3 }}
                >
                  Published By :
                  <strong> {author?.name ? author.name : author.mobile}</strong>
                </p>
              </div>
            </Item.Extra>
          </Item.Content>
        </Item>
      </Item.Group>
    </div>
  );
};
