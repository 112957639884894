import { VIEW_POST_IMAGE, VIEW_POST_VIDEO } from "../config/constants";
import queryString from "query-string";
import moment from "moment";

const GlobalController = {
  sourceResolver: (file) => {
    // Checking whether file type in "Image" or not.
    const isImage = file.type.includes("image");
    // Declaring sourceURI by checking file type(isImage).
    const sourceURI = `${
      isImage ? VIEW_POST_IMAGE(file.name) : VIEW_POST_VIDEO(file.name)
    }`;

    // Return file type: "isImage", and file address: "sourceURI"
    return { isImage, sourceURI };
  },
  queryResolver: (search) => {
    // Parse value from search
    const {
      mysearch = "",
      period,
      from = null,
      to = null,
      user,
    } = queryString.parse(search);

    // Declare TimeLap Value, Apply condition if period pass on query,
    let timeLap = 90;

    if (period === "latest") {
      timeLap = 30;
    }
    if (period === "day") {
      timeLap = 1;
    }

    if (user === "All") {
      // verified = true;
      console.log("users are all");
    }
    if (user === "Pending") {
      console.log("users are pending");
    }

    if (user === "Verified") {
      console.log("users are verified");
    }

    // Get start and end date as value in TimeLap.
    let start = moment().subtract(timeLap, "days").format("YYYY/MM/DD");
    let end = moment().format("YYYY/MM/DD");

    // Apply changes in start and end date, if from and to in query.
    if (from) {
      start = moment(from, "YYYY-MM-DD").format("YYYY/MM/DD");
    }
    if (to) {
      end = moment(to, "YYYY-MM-DD").format("YYYY/MM/DD");
    }

    // Return Search dates as start(from), end(to) and mysearch(search).
    return { start, end, mysearch };
  },
};

export default GlobalController;
