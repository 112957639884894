import React, { useState } from "react";
import { Container } from "semantic-ui-react";
import OrganizationDetailTable from "../../components/OrganizationDetailTable/OrganizationDetailTable";
import AddOrganizationButton from "../../components/AddOrganizationButton/AddOrganizationButton";
//
import { useQuery, useMutation } from "react-apollo";
import { GET_ADMIN_USERS } from "../../apollo/query";
import Placeholder from "../../components/PlaceHolder";
import { DELETE_ADMIN_USER } from "../../apollo/mutation";

const OrganizationDetails = () => {
  const [filters] = useState({
    page: 1,
    limit: 30,
    filter: {},
    search: "",
  });

  const { loading, error, data } = useQuery(GET_ADMIN_USERS, {
    variables: { ...filters },
  });

  const [handleDelete] = useMutation(DELETE_ADMIN_USER, {
    update(cache, { data: { deleteAdminUser } }) {
      // Reading Query result from cache.
      const { adminUsers } = cache.readQuery({
        query: GET_ADMIN_USERS,
        variables: { ...filters },
      });

      const deletedMember = deleteAdminUser.payload;
      const { payload } = adminUsers;

      // Filtering deleted user from query result.
      const filteredMember = payload.filter(
        (member) => member._id !== deletedMember._id
      );

      // Created updated list.
      const updatedList = {
        ...adminUsers,
        payload: filteredMember,
      };

      // Replacing cache data with new updated list.
      cache.writeQuery({
        query: GET_ADMIN_USERS,
        variables: { ...filters },
        data: { adminUsers: updatedList },
      });

      console.log("Cache updated.");
    },
  });

  if (loading)
    return (
      <Container>
        <Placeholder />
      </Container>
    );

  if (error) return <p>{`Error Occurred: ${error.message}`}</p>;

  const { adminUsers } = data;

  return (
    <React.Fragment>
      <Container>
        <AddOrganizationButton />
        <OrganizationDetailTable
          members={adminUsers.payload}
          handleDelete={handleDelete}
        />
      </Container>
    </React.Fragment>
  );
};

export default OrganizationDetails;
