import gql from "graphql-tag";

export const ADMIN_LOGIN_MUTATION = gql`
  mutation adminLogin($email: String!, $password: String!) {
    adminLogin(email: $email, password: $password) {
      payload {
        _id
        name
        email
        role
        logo
        createdAt
        updatedAt
      }
      status
      token
      message
    }
  }
`;
