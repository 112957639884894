import React from "react";
import { Table, Container, Icon } from "semantic-ui-react";

const RegisterUserTable = ({ users }) => {
  return (
    <React.Fragment>
      <Container style={{ marginTop: 20 }}>
        <Table singleLine>
          <Table.Header>
            <Table.Row textAlign="center">
              <Table.HeaderCell>FullName</Table.HeaderCell>
              <Table.HeaderCell>Mobile Number</Table.HeaderCell>
              <Table.HeaderCell>Place</Table.HeaderCell>
              <Table.HeaderCell>Follow</Table.HeaderCell>
              <Table.HeaderCell>Verified</Table.HeaderCell>
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {users.map((user, i) => {
              return <UserDetails key={i} user={user} />;
            })}
          </Table.Body>
        </Table>
      </Container>
    </React.Fragment>
  );
};

export default RegisterUserTable;

const UserDetails = ({ user }) => {
  const { name, mobile, followingUserList, verified, place } = user;

  return (
    <Table.Row textAlign="center">
      <Table.Cell>{name ? name : "Not Available"}</Table.Cell>
      <Table.Cell>{mobile ? mobile : "Not Available"}</Table.Cell>
      <Table.Cell>{place?.name ? place.name : "Not Available"}</Table.Cell>
      <Table.Cell>
        {followingUserList ? followingUserList.length : "Not Available"}
      </Table.Cell>
      <Table.Cell>
        <Icon name="circle" color={verified ? "green" : "red"} />
      </Table.Cell>
    </Table.Row>
  );
};
