import React from "react";
import { Button } from "semantic-ui-react";
import SearchBox from "../SearchBox";

const SearchBar = ({ isSearch, searching, goBack }) => {
  if (searching) return <Button onClick={() => goBack()}>Back</Button>;
  return <SearchBox isSearch={isSearch} />;
};

export default SearchBar;
