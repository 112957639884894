import React, { Component } from "react";
import { Menu, Dropdown, Icon } from "semantic-ui-react";
import { motion } from "framer-motion";
import { NavLink, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import {
  MODERATOR,
  EDITOR,
  CONTENTEDITOR,
  SENIOREDITOR,
  SUPERADMIN,
} from "../../config/routes";

class Navbar extends Component {
  state = { activeItem: "Stories" };
  handleItemClick = (e, { name }) => this.setState({ activeItem: name });
  render() {
    const { path } = this.props.match;
    const { role } = this.props.data.user;
    const superUser = role === "SUPERADMIN" || role === "SENIOREDITOR";
    let menuItems = [];
    if (role === "MODERATOR") {
      menuItems = MODERATOR;
    }
    if (role === "EDITOR") {
      menuItems = EDITOR;
    }
    if (role === "CONTENTEDITOR") {
      menuItems = CONTENTEDITOR;
    }
    if (role === "SENIOREDITOR") {
      menuItems = SENIOREDITOR;
    }
    if (role === "SUPERADMIN") {
      menuItems = SUPERADMIN;
    }
    return (
      <Menu fixed="top" style={{ backgroundColor: "#fff", height: 50 }}>
        <Menu.Menu position="right">
          {menuItems.map((item) => {
            return (
              <Menu.Item
                key={item.key}
                style={{ color: "#1B91DA" }}
                as={NavLink}
                to={item.to}
                active={path === item.to}
                onClick={this.handleItemClick}
              >
                <motion.div whileHover={{ scale: 1.09, color: "#1B91DA" }}>
                  {item.name}
                </motion.div>
              </Menu.Item>
            );
          })}

          {superUser ? (
            <Dropdown
              style={{ color: "#1B91DA" }}
              text="Admin"
              simple
              item
              direction="left"
            >
              <Dropdown.Menu>
                <motion.div whileHover={{ scale: 1.04 }}>
                  <Dropdown.Item
                    text="Organization Members"
                    style={{ color: "#1B91DA" }}
                    as={NavLink}
                    to="/members"
                    active={path === "/members"}
                  />
                </motion.div>
                <motion.div whileHover={{ scale: 1.04 }}>
                  <Dropdown.Item
                    style={{ color: "#1B91DA" }}
                    text="Registered Users"
                    as={NavLink}
                    to="/users"
                    active={path === "/users"}
                  />
                </motion.div>
                <motion.div whileHover={{ scale: 1.04 }}>
                  <Dropdown.Item
                    style={{ color: "#1B91DA" }}
                    text="Verify Requested Users"
                    as={NavLink}
                    to="/requests"
                    active={path === "/requests"}
                  />
                </motion.div>

                <motion.div whileHover={{ scale: 1.04 }}>
                  <Dropdown.Item
                    style={{ color: "#1B91DA" }}
                    text="Place"
                    as={NavLink}
                    to="/place"
                    active={path === "/place"}
                  />
                </motion.div>
                <motion.div whileHover={{ scale: 1.04 }}>
                  <Dropdown.Item
                    style={{ color: "#1B91DA" }}
                    text="Category"
                    as={NavLink}
                    to="/category"
                    active={path === "/category"}
                  />
                </motion.div>
                <motion.div whileHover={{ scale: 1.04 }}>
                  <Dropdown.Item
                    style={{ color: "#1B91DA" }}
                    text="Map"
                    as={NavLink}
                    to="/map"
                    active={path === "/map"}
                  />
                </motion.div>
              </Dropdown.Menu>
            </Dropdown>
          ) : null}

          <Menu.Item
            name="Logout"
            as={NavLink}
            to="/logout"
            style={{ color: "#1B91DA" }}
            active={path === "/logout"}
            onClick={this.handleItemClick}
          >
            <Icon name="power off" />
          </Menu.Item>
        </Menu.Menu>
      </Menu>
    );
  }
}

export default connect((state) => ({ data: state }))(withRouter(Navbar));
